import React, { useState } from 'react'
import clsx from 'clsx'

import makeStyles from '@mui/styles/makeStyles'
import { Dialog, Box, Button, Typography, Stack, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material'

import { dialogStyles, CustomRadio, CustomRadioChecked } from './styles'

const reasons = [
  {
    id: "accepted",
    text: "Jeg har accepteret et tilbud fra en producent fundet på Supplybase via telefon eller mail"
  },
  {
    id: "outside",
    text: "Jeg fortsætter dialogen med en producent udenom Supplybase"
  },
  {
    id: "cancel",
    text: "Jeg ønsker at annullere min forespørgsel"
  },
  {
    id: "benchmark",
    text: "Jeg ønskede kun at benchmarke priserne på mine emner"
  },
  {
    id: "thirdParty",
    text: "Jeg har fundet en producent udenfor Supplybase"
  },
  {
    id: "badOffers",
    text: "Ingen af de modtagne tilbud er interessante"
  }
]

export default function RejectAllDialog(props) {
  const _classes = dialogStyles()
  const classes = useStyles()

  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [reason, setReason] = useState('badOffers')
  const [finished, showFinsihed] = useState(false)

  const rejectAll = () => {
    if (isButtonDisabled) return

    setIsButtonDisabled(true)

    props.rejectAll(reason)
      .finally(function (data) {
        setIsButtonDisabled(false)
        showFinsihed(true)
      })
  }

  return (
    <Dialog open={props.open} onClose={props.close} maxWidth='sm'>
      <Box className={clsx(_classes.dialog, classes.dialog)}>

        <Stack gap={4}>
          <Typography variant='h4'>
            Afslut forespørgsel
          </Typography>

          {finished && <>
            <Typography>
              Din forespørgsel er nu afsluttet og vi informerer producenterne.
            </Typography>

            <Box alignSelf={'flex-end'}>
              <Button variant='contained' onClick={() => props.showFeedback()}>
                Fortsæt
              </Button>
            </Box>
          </>}

          {!finished && <>
            <Typography>
              Afslutter du din forespørgsel underretter vi de involverede producenter.
            </Typography>

            <Typography variant='h4'>
              Vælg årsag herunder
            </Typography>

            <FormControl component="fieldset" sx={{ textAlign: 'left', px: 4 }}>
              <RadioGroup name={"itemType"} value={reason} onChange={(e) => setReason(e.target.value)}>
                {React.Children.toArray(
                  reasons.map(r =>
                    <FormControlLabel value={r.id} control={<Radio icon={<CustomRadio />} checkedIcon={<CustomRadioChecked />} />} label={r.text} />
                  )
                )}
              </RadioGroup>
            </FormControl>

            <Typography>
              De modtagne tilbud bliver stadig gemt og kan findes under fanen "Sendte forespørgsler".
            </Typography>

            <Box display='flex' justifyContent='space-between' width='100%'>
              <Button variant='contained' color='secondary' onClick={() => props.close()}>
                Tilbage
              </Button>

              <Button variant='contained' onClick={() => rejectAll()}>
                Fortsæt
              </Button>
            </Box>
          </>}
        </Stack>

      </Box>
    </Dialog>
  )
}

const useStyles = makeStyles({
  dialog: {
    padding: '20px',
    textAlign: 'center',
    width: '100%'
  },
})