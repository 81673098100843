import React from 'react'
import clsx from 'clsx'

import makeStyles from '@mui/styles/makeStyles'
import DatePicker from '@mui/lab/DatePicker'
import { Box, Typography, FormControlLabel, TextField, Checkbox, Stack } from '@mui/material'
import { componentStyles } from '../styles'

export default function OtherInfo(props) {
  const classes = useStyles()
  const _classes = componentStyles()

  return (
    <Box className={clsx(_classes.container, classes.container)}>
      <Stack gap={2}>
        <Typography variant='h5'> Andre oplysninger </Typography>
        <TextField name={'orderName'} label="Navn på ordre (valgfri)" variant="outlined" fullWidth value={props.values.orderName} onChange={props.handleChange} helperText="Navnet er kun synligt for din profil og fremgår i din oversigt" />
        <DatePicker
          label="Sidste frist for tilbud *"
          value={props.values.offerExpire}
          minDate={new Date()}
          inputFormat={'dd/MM/yy'}
          mask={'__/__/__'}
          onChange={(value) => {
            props.handleChange({ target: { name: 'offerExpire', value } })
          }}
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
        <FormControlLabel
          label={'Hasteordre'}
          control={
            <Checkbox
              checked={Boolean(props.values.urgent)}
              onChange={(event) => props.handleChange({ target: { name: event.target.name, value: event.target.checked } })}
              name={'urgent'}
              color="primary"
            />
          }
        />
      </Stack>
    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    marginBottom: '15px'
  },
});