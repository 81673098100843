import LocationOnIcon from '@mui/icons-material/LocationOn'
import clsx from 'clsx'
import {
  Box,
  Link,
  Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { API_ENDPOINT, LOGIN_URL } from '../api'
import {companyNameToURLString} from '../helpers/utilities'

export default function SupplierOverview(props) {
  const classes = useStyles()

  const { name, logo, city, slogan } = props.company

  return (
    <Link href={`${LOGIN_URL}/virksomheder/${companyNameToURLString(name)}`} target='_blank' className={clsx(classes.company, props.small ? classes.small : props.medium ? classes.medium : classes.normal)}>

      <Box className={classes.logoContainer}>
        <img
          src={`${API_ENDPOINT}/file/image/${logo}`}
          alt=''
          className={classes.logo}
        />
        <Box className={classes.gradientOverlay} />
      </Box>

      <Box width='100%' height='50%' p={'10px'} pt={0} display='flex' flexDirection='column' justifyContent='space-between' alignItems='left' color={'black'}>
        <Typography style={{ fontWeight: 'bold' }} textAlign='left'>
          {name}
        </Typography>

        <Typography textAlign='left' variant='subtitle2' fontSize={'14px'} className={props.small && classes.smallTextOverflow}>
          {slogan}
        </Typography>

        <Box display='flex' alignItems={'center'}>
          <LocationOnIcon fontSize='small' />
          <Box ml={1}>
            <Typography variant='subtitle2'>
              {city}
            </Typography>
          </Box>
        </Box>
      </Box>

    </Link>
  )
}

const useStyles = makeStyles({
  company: {
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.20)',
    margin: '0 auto'
  },
  normal: {
    maxWidth: '260px',
    minWidth: '220px',
    height: '380px',
  },
  small: {
    width: '190px',
    height: '280px',
  },
  medium: {
    height: '350px',
    maxWidth: '260px',
    minWidth: '220px',
  },
  logo: {
    maxHeight: '95%',
    maxWidth: '95%',
    margin: 'auto'
  },
  smallTextOverflow: {
    height: '50px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  gradientOverlay: {
    position: 'absolute',
    width: '100%',
    height: '95%',
    background: 'linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, rgba(0, 0, 0, 0.25) 100%)',
  },
  logoContainer: {
    height: '60%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '100%'
  }
});