import ErrorIcon from '@mui/icons-material/Error'
import {
  Box, Button, Stack, Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import NumberFormat from 'react-number-format'
import * as fileApi from '../../api/file'

const cells = [
  { id: 'name', label: 'Navn', subLabel: '(.pdf, .png, .jpeg)', align: 'left', sortable: true },
  { id: 'amount', label: 'Type', subLabel: '(.dxf, .dwg)', align: 'right', sortable: true },
  { id: 'value', label: 'Filer', subLabel: '(.stp, .iges, .stl)', align: 'right', sortable: true },
  { id: 'quantity', label: 'Antal', align: 'right', sortable: true },
  { id: 'price', label: 'Stk. pris', align: 'right', sortable: true },
  { id: 'total', label: 'Pris', align: 'right', sortable: true }
]

export default function OrderItemsTable(props) {
  const classes = useStyles()

  const downloadFile = (fileName, orderItemFileId, fileId) => {
    fileApi.getFile(fileName, orderItemFileId, fileId)
  }

  return (
    <React.Fragment>
      <Table className={classes.fileTable} size='small'>

        <TableHead>
          <TableRow className={classes.tableHeader}>

            {cells.map(cell => (
              <TableCell key={cell.id} align={"center"} style={{ border: '2px solid white' }}>
                <Box color='white' display='flex' justifyContent='center' alignItems='center'>
                  <Typography variant='h6'>{cell.label}</Typography>
                </Box>
              </TableCell>
            ))}

          </TableRow>
        </TableHead>

        <TableBody>

          {props.orderItems.map((orderItem, rowIndex) => (
            <TableRow key={orderItem.identifier}>

              <TableCell>
                <Typography variant='h6' noWrap>
                  {orderItem.name}
                </Typography>
              </TableCell>

              <TableCell>
                <Typography variant='h6'>
                  {orderItem.type === 'single' ? 'Emne' : orderItem.type === 'construction' ? 'Konstruktion' : ''}
                </Typography>
              </TableCell>

              <TableCell>
                <Stack direction={'row'} columnGap={2} rowGap={0.5} justifyContent={'center'} flexWrap={'wrap'}>
                  {React.Children.toArray(
                    orderItem.files.map(item => <Stack direction={'row'} alignItems={'center'} gap={0.5}>
                      <Button
                        style={{ textTransform: 'none', color: '#000' }}
                        onClick={() => { downloadFile(item.name, orderItem.id, item.id) }}
                        endIcon={item.changed ? <ErrorIcon color='red' /> : null}
                      >
                        {item.name}
                      </Button>
                    </Stack>)
                  )}
                </Stack>
              </TableCell>

              <TableCell>
                <Typography>
                  {orderItem.quantity}
                </Typography>
              </TableCell>

              <TableCell>
                <Typography>
                  <NumberFormat
                    value={Number(orderItem.price)}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    suffix={' kr.'}
                    decimalScale={2}
                  />
                </Typography>
              </TableCell>

              <TableCell>
                <Typography variant='h6' noWrap>
                  <NumberFormat
                    value={Number(orderItem.quantity * orderItem.price)}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    suffix={' kr.'}
                    decimalScale={2}
                  />
                </Typography>
              </TableCell>

            </TableRow>
          ))}
        </TableBody>

      </Table>
    </React.Fragment>
  )
}

const useStyles = makeStyles({
  fileTable: {
    width: '100%',
    borderCollapse: 'collapse',
    background: 'white'
  },
  tableHeader: {
    backgroundColor: '#061B37',
  },
  amountInput: {
    width: '65px',
    height: '40px',
    border: '2px solid #DD8500',
    textAlign: 'center',
    borderRadius: '5px',
    padding: 0
  },
  amountContainer: {
    border: 'none !important',
    '&::before': {
      border: 'none !important'
    },
    '&::after': {
      border: 'none !important'
    }
  }
})