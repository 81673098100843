import React, { useContext, useEffect, useRef, useState } from 'react'

import {
  Box, Step, StepButton, StepLabel, Stepper, Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import * as certificationApi from '../../api/certification'
import * as materialApi from '../../api/material'
import * as methodApi from '../../api/method'
import * as supplierApi from '../../api/supplier'

import { CompanyContext } from '../../contexts/companyContext'
import { UserContext } from '../../contexts/userContext'
import StepFour from './stepFour'
import StepOne from './stepOne'
import StepThree from './stepThree'
import StepTwo from './stepTwo'

const steps = [
  'Tilføj filer',
  'Vælg producenter',
  'Tilføj oplysninger',
  'Send'
]

function Wizard(props) {
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0);

  const [RFQType, setRFQType] = useState() // Open or Direct RFQ
  const [orderItems, setOrderItems] = useState([])
  const [suppliers, setSuppliers] = useState([])
  const [requirements, setRequirements] = useState({
    methods: [],
    materials: [],
    certifications: [],
  })

  const [supplierMethods, setSupplierMethods] = useState([])
  const [supplierMaterials, setSupplierMaterials] = useState([])
  const [supplierCertifications, setSupplierCertifications] = useState([])
  const [locations, setLocations] = useState([])

  const company = useContext(CompanyContext)
  const user = useContext(UserContext)
  const containerRef = useRef(null)

  const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  const [isGuest] = useState(user === undefined)

  const [values, setValues] = useState({
    comment: '',
    deliveryMethod: -1,
    otherDeliveryAddress: 0,
    address: company?.info.address || '',
    city: company?.info.city || '',
    postal: company?.info.postal || '',
    name: user?.info.name || '',
    phone: user?.info.phone || '',
    email: user?.info.email || '',
    company: company?.info.name || '',
    invoiceMail: company?.info.invoiceMail || '',
    offerExpire: addDays(new Date(), 14),
    urgent: false,
    orderName: '',
    orderType: '',
    deliverySameAs: "true",
    deliveryStreet: '',
    deliveryCity: '',
    deliveryPostal: '',
    ndaType: 'none',
    ndaFile: null,
    ndaFileId: null,
    acceptTerms: false,
    acceptNews: false
  })

  useEffect(() => {
    async function fetchFilterGroups() {
      setSupplierMethods(await methodApi.getSupplierMethods())
      setSupplierMaterials(await materialApi.getSupplierMaterials())
      setSupplierCertifications(await certificationApi.getSupplierCertifications())
      setLocations(await supplierApi.getLocations())
    }

    fetchFilterGroups()
  }, [])

  const handleNDAChange = (nda) => {
    setValues({ ...values, ndaFile: nda });
  }

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  }

  const cleanUpFiles = () => {
    setOrderItems(orderItems.filter(orderItem => orderItem.files?.length > 0))
  }

  const storeFile = async (file, orderItem) => {
    setOrderItems(prevState => {
      const rowIndex = prevState.findIndex(item => item === orderItem)
      file.storageId = "1"
      file.extension = file.name.slice(file.name.lastIndexOf(".")).toLowerCase()
      console.log(orderItem)
      orderItem.files.push(file)
      return Object.assign([], prevState, { [rowIndex]: orderItem })
    })
  }

  const scrollToCompanies = () => {
    containerRef.current.scrollTo({
      left: 0,
      top: 500,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    if (suppliers.length === 3) {
      containerRef.current.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth',
      })
    }
  }, [suppliers])

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <StepOne
          orderItems={orderItems}
          setOrderItems={setOrderItems}
          handleChange={handleChange}
          storeFile={storeFile}
          cleanUpFiles={cleanUpFiles}
          values={values}
          back={handleBack}
          next={handleNext}
        />
      case 1:
        return <StepTwo
          RFQType={RFQType}
          setRFQType={setRFQType}
          suppliers={suppliers}
          setSuppliers={setSuppliers}
          requirements={requirements}
          setRequirements={setRequirements}
          locations={locations}
          supplierMethods={supplierMethods}
          supplierMaterials={supplierMaterials}
          supplierCertifications={supplierCertifications}
          back={handleBack}
          next={handleNext}
          scrollToCompanies={scrollToCompanies}
        />
      case 2:
        return <StepThree
          values={values}
          handleChange={handleChange}
          back={handleBack}
          next={handleNext}
          handleNDAChange={handleNDAChange}
          isGuest={isGuest}
        />
      case 3:
        return <StepFour
          isGuest={isGuest}
          RFQType={RFQType}
          values={values}
          orderItems={orderItems}
          suppliers={suppliers}
          requirements={requirements}
          supplierMethods={supplierMethods}
          supplierMaterials={supplierMaterials}
          supplierCertifications={supplierCertifications}
          handleChange={handleChange}
          back={handleBack}
          next={handleNext}
        />
      default:
        return '';
    }
  }

  const handleNext = () => {
    if (activeStep < 3) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      props.setEdit(false)
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleStep = (step) => {
    return
  }

  function SquareStep(props) {
    const { active, icon } = props

    return (
      <Box className={classes.squareStep} bgcolor={active ? "#DD8500" : "#C7C7C7"}>
        <Typography variant='h5'>{icon}</Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.wizardContainer} ref={containerRef}>

      <Stepper activeStep={activeStep} nonLinear style={{ background: 'inherit', padding: '0' }}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton onClick={() => handleStep(index)}>
              <StepLabel StepIconComponent={SquareStep}>
                <Typography variant='h5'>{label}</Typography>
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>

      {getStepContent(activeStep)}

    </Box>
  )
}

export default Wizard

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  wizardContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px',
    minHeight: '500px',
    width: '100%',
    overflowY: 'scroll'
  },
  squareStep: {
    width: '30px',
    height: '30px',
    borderRadius: '5px',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
});