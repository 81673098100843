import clsx from 'clsx';
import React from 'react';

import { Box, Checkbox, FormControlLabel, Link, Stack, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { componentStyles } from '../styles';
import { LOGIN_URL } from '../../api';

export default function TermsOfService(props) {
  const classes = useStyles()
  const _classes = componentStyles()

  return (
    <Stack gap={1} className={clsx(_classes.container, classes.container)}>
      <Typography variant='h5' required>
        Vilkår og betingelser
      </Typography>

      <Box>
        <Typography>
          Vi opretter en gratis profil til dig hvor du kan tilgå de tilbud du modtager fra producenter. Du modtager en mail til aktivering af din konto når du indsender din ordre.
        </Typography>
      </Box>

      <FormControlLabel
        label={<Box>Jeg accepterer <Link href={LOGIN_URL + '/terms'} target='_blank'> vilkår og betingelser</Link></Box>}
        control={
          <Checkbox
            checked={Boolean(props.values.acceptTerms)}
            onChange={(event) => props.handleChange({ target: { name: event.target.name, value: event.target.checked } })}
            name={'acceptTerms'}
            color="primary"
          />
        }
      />

      <FormControlLabel
        label={'Jeg vil gerne modtage nyheder om Supplybase'}
        control={
          <Checkbox
            checked={Boolean(props.values.acceptNews)}
            onChange={(event) => props.handleChange({ target: { name: event.target.name, value: event.target.checked } })}
            name={'acceptNews'}
            color="primary"
          />
        }
      />
    </Stack >
  )
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    marginBottom: '15px'
  },
});