import React from 'react'
import clsx from 'clsx'

import makeStyles from '@mui/styles/makeStyles';
import {
  Dialog,
  Typography,
  Box,
  Button
} from '@mui/material'

import { dialogStyles } from './styles'

export function OrderCreatedDialog(props) {
  const _classes = dialogStyles()
  const classes = useStyles()

  const renderQuestion = (question, answer) => {
    return (
      <Box display='flex' width='100%' mb={4}>
        <Box width={'45%'} textAlign='right' borderBottom={'1px solid white'} pb={1} height='fit-content' mr={2}>
          <Typography variant='h5'>
            {question}
          </Typography>
        </Box>

        <Box width={'55%'}>
          <Typography variant='body1'>
            {answer}
          </Typography>
        </Box>
      </Box>
    )
  }
  return (
    <Dialog open={props.open} onClose={props.close} maxWidth='xl'>
      <Box className={clsx(_classes.dialog, classes.dialog)}>

        <Box mb={2}>
          <Typography variant='h4'>
            Din forespørgsel er sendt!
          </Typography>
        </Box>

        <Box display='flex' alignItems='baseline' mb={3}>
          <Typography variant='h5'>
            Ordrenummer:
          </Typography>
          <Box color='#DD8500' ml={0.5}>
            <Typography variant='h5'>
              {props.orderNumber}
            </Typography>
          </Box>
        </Box>

        {renderQuestion('Hvad sker der nu?', 'Du modtager en ny mail hver gang en producent har reageret på din henvendelse.')}
        {renderQuestion('Hvad hvis det første tilbud er OK?', 'Du vælger selv om du vil acceptere det første modtagne tilbud eller vente på at flere producenter besvarer din henvendelse. Accepterer du et tilbud underetter vi automatisk de resterende producenter.')}
        {renderQuestion('Hvad hvis producenten ikke reagerer på min henvendelse?', '3 dage før tilbuddets udløb underettes de virksomheder der mangler at reagere på din henvendelse. Hvis der efter tilbuddets udløb stadig er producenter der mangler at svare, så kan du vælge forlænge fristen ved at redigerer din forespørgsel.')}
        {renderQuestion('Har du andre spørgsmål?', 'Har du andre spørgsmål er du velkommen til at kontakte Supplybase ved at ringe på telefon 93809085 eller skrive til support@supplybase.dk')}

        <Box alignSelf='flex-end' mr={2}>
          <Button variant='contained' onClick={() => props.close()}>
            Afslut
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

const useStyles = makeStyles({
  dialog: {
    padding: '20px',
    width: '950px'
  },
})