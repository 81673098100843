import clsx from 'clsx'
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

import {
  Box,
  Button,
  Stack,
  Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'


export function Dropzone(props) {
  const onDrop = useCallback(acceptedFile => {
    props.uploadFile(acceptedFile)
  }, [props])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: props.fileTypes })
  const classes = useStyles()

  return (
    <div {...getRootProps()} style={{ outline: 'none', width: '100%', height: '100%' }}>
      <input {...getInputProps()} />
      {
        <Box p={2} className={isDragActive ? clsx(classes.dropZone, classes.active) : classes.dropZone}>

          <Stack gap={3}>
            <Typography variant='h5' style={{ color: '#8B8B8B' }} align='center'>
              Træk filer hertil
            </Typography>

            <Typography variant='body' style={{ color: '#8B8B8B' }} align='center'>
              Understøttede filformater: PDF, STEP, IGES, DWG, DFX, JPEG, PNG
            </Typography>

            <Box alignSelf='center'>
              <Button color='primary' variant='contained'>
                Upload
              </Button>
            </Box>
          </Stack>

        </Box>
      }
    </div>
  )
}

const useStyles = makeStyles({
  dropZone: {
    background: '#E5E5E5',
    border: '2px dashed #DD8500',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 'none',
    cursor: 'pointer',
    width: '100%',
    height: '100%'
  },
  active: {
    borderColor: '#DD8500',
    boxShadow: '0 0 10px #DD8500',
    border: '2px solid #DD8500',
  },
})
