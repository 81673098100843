import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { useState } from 'react'
import ContactInfo from '../../components/newOrder/otherInfo'
import CustomerInfo from '../../components/newOrder/customerInfo'
import ShippingOptions from '../../components/newOrder/shippingOptions'
import DeliveryAddress from '../../components/newOrder/deliveryAddress'
import Nda from '../../components/newOrder/nda'
import OrderType from '../../components/newOrder/orderType'
import { useSnackbar } from 'notistack'

import { Actions } from './actions'
import { containerStyles } from './styles'
import TermsOfService from '../../components/guest/termsOfService'

export default function StepThree(props) {
  const _classes = containerStyles()
  const classes = useStyles()
  const [showError, setShowError] = useState(false)
  const { enqueueSnackbar } = useSnackbar();

  const next = async () => {
    const { name, phone, invoiceMail, deliveryMethod,
      address, city, postal, email, company, orderType,
      deliverySameAs, deliveryStreet, deliveryCity, deliveryPostal,
      offerExpire, ndaType, ndaFile, acceptTerms } = props.values

    if (!name || !phone || !invoiceMail || !email || !address || !city || !postal || !company || (deliveryMethod === "1" && deliverySameAs === 'false' && (!deliveryStreet || !deliveryCity || !deliveryPostal))) {
      enqueueSnackbar('Nogle felter mangler at blive udfyldt', { variant: 'error' })
      setShowError(true)
      return
    }

    if (ndaType === 'uploaded' && !ndaFile) {
      enqueueSnackbar('Upload en NDA', { variant: 'error' })
      return
    }
    if (!offerExpire || !(offerExpire instanceof Date && !isNaN(offerExpire.valueOf()))) {
      enqueueSnackbar('Sidste frist for tilbud mangler', { variant: 'error' })
      return
    }
    if (deliveryMethod < 0) {
      enqueueSnackbar('Vælg en leveringsmulighed', { variant: 'error' })
      return
    }
    if (!orderType) {
      enqueueSnackbar('Vælg type af henvendelse', { variant: 'error' })
      return
    }

    if (props.isGuest && !acceptTerms) {
      enqueueSnackbar('Accepter vilkår og betingelser', { variant: 'error' })
      return
    }

    props.next()
  }

  return (
    <Box className={clsx(_classes.container, classes.container)}>

      <Box display='flex'>
        <Box width={'50%'} mr={2}>
          <CustomerInfo values={props.values} handleChange={props.handleChange} showError={showError} />
          <OrderType values={props.values} handleChange={props.handleChange} showError={showError} />
        </Box>

        <Box width={'50%'}>
          <ShippingOptions values={props.values} handleChange={props.handleChange} showError={showError} />
          {props.values.deliveryMethod === "1" &&
            <DeliveryAddress values={props.values} handleChange={props.handleChange} showError={showError} />
          }
          <ContactInfo values={props.values} handleChange={props.handleChange} showError={showError} />
          <Nda values={props.values} handleChange={props.handleChange} handleNDAChange={props.handleNDAChange} />

          {props.isGuest && <TermsOfService values={props.values} handleChange={props.handleChange} />}
        </Box>

      </Box>

      <Actions next={next} back={props.back} />

    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    maxWidth: '1150px',
    margin: '20px auto',
    padding: '0'
  }
});