import React, { useState } from 'react'
import clsx from 'clsx'
import { motion } from 'framer-motion'

import makeStyles from '@mui/styles/makeStyles';
import {
  Box,
  Typography,
  Button,
  Link,
  Skeleton
} from '@mui/material'

import LocationOnIcon from '@mui/icons-material/LocationOn';
import { componentStyles } from '../styles'
import { API_ENDPOINT, LOGIN_URL } from '../../api'
import { companyNameToURLString } from '../../helpers/utilities';

export default function Overview(props) {
  const classes = useStyles()
  const _classes = componentStyles()

  const [imageLoaded, setImageLoaded] = useState(false)

  const { id, name, logo, slogan, city } = props.company
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: "linear", duration: 0.2 }}
      className={clsx(classes.container, _classes.shadow)}
    >
      <Box className={classes.logoContainer}>
        {!imageLoaded &&
          <Skeleton variant="rectangular" width={'95%'} height={'95%'} />
        }
        <img
          src={`${API_ENDPOINT}/file/image/${logo}`}
          alt=''
          className={classes.logo}
          style={{ display: imageLoaded ? 'initial' : 'none' }}
          onLoad={() => setImageLoaded(true)}
        />
      </Box>

      <Box className={classes.contentContainer} display='flex' flexDirection='column' justifyContent='space-between'>
        <Box>
          <Typography variant='h5'>{name}</Typography>

          <Box mt={2}>
            <Typography variant='subtitle2'>"{slogan}"</Typography>
          </Box>
        </Box>

        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Box display='flex'>
            <Box mr={2}>
              <LocationOnIcon />
            </Box>
            <Typography>{city}</Typography>
          </Box>

          <Box display='flex'>
            <Box mr={1}>
              <Link href={`${LOGIN_URL}/virksomheder/${companyNameToURLString(name)}`} target='_blank'>
                <Button variant='contained' color='secondary'>Vis profil</Button>
              </Link>
            </Box>

            <Box>
              <Button
                disabled={props.suppliers.find(s => s.id === id) || props.suppliers.length > 2}
                variant='contained'
                onClick={() => props.setSuppliers(arr => [...arr, props.company])}>
                Vælg
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

    </motion.div>
  );
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    maxWidth: '735px',
    height: '170px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '12px',
    cursor: 'pointer',
    padding: '16px',
    backgroundColor: 'white'
  },
  logoContainer: {
    width: '30%',
    height: '100%',
    display: 'flex',
  },
  logo: {
    maxHeight: '95%',
    maxWidth: '95%',
    margin: 'auto'
  },
  contentContainer: {
    width: '68%'
  }
});