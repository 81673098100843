import React, { useEffect, useState } from 'react'

import {
  Box, Button, CircularProgress, Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { useHistory, useParams } from 'react-router-dom'
import * as offerApi from '../api/offer'
import * as orderApi from '../api/order'
import SupplierOverview from '../components/compareOffers/supplierOverview'
import SuppliersTable from '../components/compareOffers/suppliersTable'
import AcceptOfferDialog from '../components/dialogs/acceptOfferDialog'
import AddPurchaseNumberDialog from '../components/dialogs/addPoDialog'
import FeedbackDialog from '../components/dialogs/feedbackDialog'
import NewUpdateOnOfferDialog from '../components/dialogs/newUpdateOnOfferDialog'
import RejectAllDialog from '../components/dialogs/rejectAllDialog'
import Order from '../containers/orders/order'
import { OrderStatus } from '../helpers/enums'
import { pageStyles } from './styles'

export default function CompareOffers(props) {
  const classes = useStyles()
  const _classes = pageStyles()

  const history = useHistory()

  const [offerId, setOfferId] = useState()
  const [orderOverview, setOrderOverview] = useState()

  const [rejectAllDialog, showRejectAllDialog] = useState(false)
  const [feedbackDialog, showFeedbackDialog] = useState(false)
  const [confirmOffer, setConfirmOffer] = useState(null)
  const [PODialog, showPODialog] = useState(false)
  const [isAcceptDisabled, setIsAcceptDisabled] = useState(false)
  const [showNewUpdate, setShowNewUpdate] = useState(false)

  let { orderId } = useParams();

  useEffect(() => {
    getData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId])

  async function getData() {
    const order = await orderApi.getOrderOverview(orderId)

    setOrderOverview(order)
  }

  function onAfterUpdateDialog() {
    setShowNewUpdate(false)
    getData()
  }

  const acceptOffer = async (offer) => {
    if (isAcceptDisabled) return
    setIsAcceptDisabled(true)
    const actualOffer = await orderApi.getOrder(orderId, offer.id)

    if (actualOffer.updatedAt > offer.updatedAt) {
      setConfirmOffer(null)
      setShowNewUpdate(true)
      setIsAcceptDisabled(false)
      return
    }

    offerApi.acceptOffer(offer.id)
      .then(function (response) {
        showPODialog(true)
      }).catch(error => console.log(error))
      .finally(function () {
        setIsAcceptDisabled(false)
      })
  }

  const showFeedback = () => {
    showRejectAllDialog(false)
    showFeedbackDialog(true)
  }

  const rejectAll = async (reason) => {
    await offerApi.rejectAll(orderId, reason)
  }

  const sendFeedback = async (rating, comment) => {
    await orderApi.feedback(orderId, rating, comment)
  }

  const finish = async (purchaseNumber) => {
    if (purchaseNumber) {
      await orderApi.addPurchaseNumber(purchaseNumber, orderId)
    }

    history.push('/orders')
  }

  if (!orderOverview) {
    return <Box my={2}>
      <CircularProgress />
    </Box>
  }

  return (
    <Box className={classes.container}>
      <Box className={_classes.pageTitle}>
        <Typography variant='h3'>{`Sammenlign tilbud`}</Typography>
      </Box>

      <Box style={{ overflowY: 'scroll' }} p={3}>

        {offerId && <Order
          id={orderId}
          offerId={offerId}
          back={() => setOfferId()}
          acceptOffer={(offer) => setConfirmOffer(offer)}
        />}

        {!offerId && <>
          <Box display='flex' width='100%' alignItems='flex-start'>
            <SupplierOverview
              orderId={orderOverview.id}
              rfqType={orderOverview.rfqType}
              requirements={orderOverview.orderFilters}
              orderRequests={orderOverview.orderRequests}
            />

            <SuppliersTable
              orderId={orderOverview.id}
              orderRequests={orderOverview.orderRequests}
              setOffer={setOfferId}
            />
          </Box>

          <Box display='flex' justifyContent='space-between' mt={3} pb={2}>
            <Button variant='contained' color='secondary' onClick={() => history.push('/orders')}>
              Tilbage
            </Button>

            {[OrderStatus.OPEN, OrderStatus.EXPIRED].includes(orderOverview.status) && <Box display='flex'>
              <Button variant='contained' color='red' onClick={() => showRejectAllDialog(true)}>
                Afslut forespørgsel
              </Button>
            </Box>}

          </Box></>}

      </Box>

      {confirmOffer && <AcceptOfferDialog open={Boolean(confirmOffer)} close={() => setConfirmOffer(null)} offer={confirmOffer} acceptOffer={acceptOffer} />}
      <NewUpdateOnOfferDialog open={showNewUpdate} onNext={() => onAfterUpdateDialog()}></NewUpdateOnOfferDialog>
      <AddPurchaseNumberDialog open={PODialog} continue={finish} />
      <RejectAllDialog open={rejectAllDialog} close={() => showRejectAllDialog(false)} rejectAll={rejectAll} showFeedback={showFeedback} />
      {feedbackDialog && <FeedbackDialog open={feedbackDialog} close={() => {showFeedbackDialog(false); history.push('/orders')}} sendFeedback={sendFeedback} />}

    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    paddingTop: '56px',
    flexDirection: 'column',
  },
});