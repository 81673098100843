import React from 'react'
import {
  Dialog,
  Button,
  Typography,
  Stack
} from '@mui/material'

export default function NewUpdateOnOfferDialog(props) {
  return (
    <Dialog open={props.open}>
      <Stack gap={2} sx={container}>
        <Typography variant='h4'>
          Dette tilbud har fået nye opdateringer!
        </Typography>
        <Typography>
          Se venligst ændringerne inden accept.
        </Typography>
        <Button variant='contained' onClick={() => props.onNext()}>
          Forstået
        </Button>
      </Stack>
    </Dialog>
  )
}

const container = {
  background: 'radial-gradient(#102D53 0%, #061B37 100%)',
  boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.25)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  color: 'white'
}

