const deliveryMethods = {
  0: 'Ordren afhentes hos producenten',
  1: 'Ordren skal sendes',
}

const orderTypes = {
  BENCHMARK: 'Benchmark',
  PROTOTYPE: 'Rapid prototyping',
  TEST: 'Testlevering inden masseproduktion',
  NORMAL: 'Alment indkøb'
}

const orderStatus = {
  OPEN: 'open',
  COMPLETED: 'completed',
  BENCHMARKED: 'benchmarked',
  CLOSED: 'closed',
  EXPIRED: 'expired'
}

const orderRequestProgress = {
  NEW: 'new',
  OFFER_SENT: 'offerSent',
  REJECTED: 'rejected',
  PENDING_CHANGES: 'pendingChanges',
  NEW_PENDING_CHANGES: 'newWithChanges'
}

exports.OrderStatus = orderStatus
exports.DeliveryMethods = deliveryMethods
exports.OrderRequestProgress = orderRequestProgress
exports.OrderTypes = orderTypes