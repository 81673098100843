import React from 'react'
import clsx from 'clsx'
import NumberFormat from 'react-number-format'

import makeStyles from '@mui/styles/makeStyles'
import {
  Dialog,
  Box,
  Button,
  Typography
} from '@mui/material'

import { dialogStyles } from './styles'
import SupplierOverview from '../supplierOverview'
import { formatDate } from '../../helpers/utilities'
import * as offerApi from '../../api/offer'

export default function AcceptOfferDialog(props) {
  const _classes = dialogStyles()
  const classes = useStyles()

  const renderInfo = (name, value, offerId) => {
    return <Box mb={3} textAlign='left'>

      <Box width='130px'>
        <Typography>
          {name}:
        </Typography>
      </Box>

      <Box width='200px'>
        {offerId
          ? <Box onClick={() => offerApi.getOfferFile(offerId, value)} style={{ textDecoration: 'underline', color: '#7B61FF', cursor: 'pointer' }}>
            {value}
          </Box>

          : <Typography variant='h5'>
            {value}
          </Typography>
        }
      </Box>

    </Box>
  }

  return (
    <Dialog open={props.open} onClose={props.close} maxWidth='lg'>
      <Box className={clsx(_classes.dialog, classes.dialog)}>

        <Box mb={3}>
          <Typography variant='h4'>
            Ønsker du at acceptere følgende tilbud?
          </Typography>
        </Box>

        <Box mb={6} className={classes.offerContainer} p={2}>

          <SupplierOverview company={props.offer.supplier} medium />

          <Box width='50%' display='flex' flexDirection={'column'} alignItems={'center'}>
            {renderInfo('Pris', <NumberFormat value={Number(props.offer.total)} displayType={'text'} decimalScale={2} thousandSeparator={'.'} decimalSeparator={','} suffix={' DKK'} />)}
            {renderInfo('Levering', formatDate(new Date(props.offer.deliveryDate), 'PP'))}
            {renderInfo('Tilbud', props.offer.fileName, props.offer.id)}
          </Box>

        </Box>

        <Box display='flex' justifyContent='space-between' width='100%'>
          <Button variant='contained' color='secondary' onClick={() => props.close()}>
            Tilbage
          </Button>

          <Button variant='contained' onClick={() => props.acceptOffer(props.offer)}>
            Accepter
          </Button>
        </Box>

      </Box>
    </Dialog>
  )
}

const useStyles = makeStyles({
  dialog: {
    padding: '20px',
    textAlign: 'center',
    width: '715px'
  },
  offerContainer: {
    width: '670px',
    height: '390px',
    background: '#FFFFFF',
    color: 'black',
    borderRadius: '10px',
    display: 'flex',
  }
})