import React from 'react'

import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Button
} from '@mui/material'

export function Actions(props) {
  const classes = useStyles()

  return (
    <Box className={classes.actionsContainer}>

      <Box mr={2}>
        <Button disabled={false} onClick={props.back} variant="contained" color='secondary'>
          Tilbage
        </Button>
      </Box>

      {props.next && <Button variant="contained" color="primary" onClick={props.next} disabled={props.disabled}>
        {props.finished ? 'Godkend og Send' : 'Fortsæt'}
      </Button>}
    </Box>
  )
}

const useStyles = makeStyles({
  actionsContainer: {
    width: '100%',
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'space-between'
  }
});