import axios from 'axios'
import { API_ENDPOINT } from '.'

const API_ROUTE = `${API_ENDPOINT}/data/material`

export async function getSupplierMaterials() {
  const url = `${API_ROUTE}/supplierMaterials`
  const response = await axios.get(url)
    .catch(err => err.response)

  return response ? response.data : null
}