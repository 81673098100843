import React from 'react'

import {
  Box,
  Snackbar,
} from '@mui/material'

export default function ShowSnackbar(props) {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    props.setState(false);
  };

  return (
    <Box>
      <Snackbar
        anchorOrigin={props.position || { vertical: 'bottom', horizontal: 'center' }}
        open={props.state}
        autoHideDuration={props.duration || 6000}
        onClose={handleClose}
        onClick={handleClose}
        message={props.text}
      />
    </Box>
  );
}