import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import {
  IconButton,
} from '@mui/material'

import AddCircleIcon from '@mui/icons-material/AddCircle'
import ShowSnackbar from './snackbar'

//const acceptedFileTypes = ['.pdf', '.png', '.jpeg', '.jpg', '.dxf', '.dwg', '.stp', '.step', '.iges', '.stl']

export function AddFileButton(props) {
  const [unsupportedFileType, showUnsupportedFileType] = useState(false)

  const onDrop = useCallback(acceptedFile => {
    if (acceptedFile.length < 1) {
      showUnsupportedFileType(true)
      return
    }

    props.uploadFile(acceptedFile[0])
  }, [props])

  const { getRootProps, getInputProps } = useDropzone({ onDrop, noDrag: true, maxFiles: 1, accept: props.acceptedTypes })

  return (
    <React.Fragment>

      <div {...getRootProps()} style={{ outline: 'none', width: '100%', height: '100%' }}>
        <input {...getInputProps()} />
        {
          <IconButton>
            <AddCircleIcon style={{ color: '#18BC3C' }} />
          </IconButton>
        }
      </div>

      <ShowSnackbar
        text={'Filtypen er ikke understøttet'}
        state={unsupportedFileType}
        setState={showUnsupportedFileType}
      />
    </React.Fragment>
  )
}