import React from 'react'
import clsx from 'clsx'

import makeStyles from '@mui/styles/makeStyles';
import { Box, Typography, TextField, Stack } from '@mui/material'

import { componentStyles } from '../styles'

export default function CustomerInfo(props) {
  const classes = useStyles()
  const _classes = componentStyles()

  return (
    <Box className={clsx(_classes.container, classes.container)}>

      <Stack gap={2}>
        <Typography variant='h5'> Kontaktoplysninger</Typography>
        <TextField name={'name'} label="Navn" variant="outlined" fullWidth required value={props.values.name} onChange={props.handleChange} error={!props.values.name && props.showError} />
        <TextField name={'phone'} label="Telefonnr." variant="outlined" fullWidth required value={props.values.phone} onChange={props.handleChange} error={!props.values.phone && props.showError} />
        <TextField name={'email'} label="Email" variant="outlined" fullWidth required value={props.values.email} onChange={props.handleChange} error={!props.values.email && props.showError} />
      </Stack>

      <Stack gap={2} mt={4}>
        <Typography variant='h5' >Faktureringsaddresse</Typography>
        <TextField name={'company'} label="Virksomhed" variant="outlined" fullWidth required value={props.values.company} onChange={props.handleChange} error={!props.values.company && props.showError} />
        <TextField name={'invoiceMail'} label="Email til faktura" variant="outlined" fullWidth required value={props.values.invoiceMail} onChange={props.handleChange} error={!props.values.invoiceMail && props.showError} />
        <TextField name={'address'} label="Adresse" variant="outlined" fullWidth required value={props.values.address} onChange={props.handleChange} error={!props.values.address && props.showError} />
        <Box display='flex' >
          <TextField name={'postal'} sx={{ mr: 2 }} label="Postnr." variant="outlined" required value={props.values.postal} onChange={props.handleChange} error={!props.values.postal && props.showError} />
          <TextField name={'city'} label="By" variant="outlined" fullWidth required value={props.values.city} onChange={props.handleChange} error={!props.values.city && props.showError} />
        </Box>
      </Stack>

    </Box >
  )
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    marginBottom: '15px'
  },
});