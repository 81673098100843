import {
  Box, Tab, Tabs, Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import CompletedOrders from '../components/orders/completedOrders'
import OrderList from '../components/orders/orderList'
import OpenOrder from '../containers/orders/openOrder'
import { useQuery } from '../helpers/hooks'
import { pageStyles } from './styles'

export default function Orders() {
  const classes = useStyles()
  const _classes = pageStyles()
  const query = useQuery()

  const [value, setValue] = useState(0);
  const [orderId, setOrderId] = useState(query.get('orderId') ? query.get('orderId') : null)
  const [offerId] = useState(query.get('offerId') || null)

  useEffect(() => {
    switch (query.get('status')) {
      case 'open':
        setValue(0)
        break
      case 'completed':
        setValue(1)
        break
      case 'requests':
        setValue(2)
        break
      default:
        setValue(0)
    }
  }, [query])

  return (
    <Box className={classes.container}>
      <Box className={_classes.pageTitle}>
        <Typography variant='h3'>Ordrer</Typography>
      </Box>

      <Box width='100%' p={3} style={{ overflowY: 'scroll' }}>
        <Tabs value={value} onChange={(e, value) => setValue(value)} textColor="#000" indicatorColor="primary">
          <Tab label={'Sendte forespørgsler'} value={0} />
          <Tab label={'Accepterede tilbud'} value={1} />
        </Tabs>

        <Box mt={3}>
          {value === 0 &&
            <React.Fragment>
              {!orderId && <OrderList setOrderId={setOrderId} />}
              {orderId && <OpenOrder orderId={orderId} setOrderId={setOrderId} offerId={offerId} />}
            </React.Fragment>
          }

          {value === 1 &&
            <React.Fragment>
              <CompletedOrders setOrderId={setOrderId} />
            </React.Fragment>
          }

        </Box>

      </Box>

    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    paddingTop: '56px',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }
});