import MessageIcon from '@mui/icons-material/Message'
import {
  Box, Stack, Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { daDK, DataGrid } from '@mui/x-data-grid'
import lodash from 'lodash'
import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import { useHistory } from "react-router-dom"
import * as orderApi from '../../api/order'
import { OrderStatus } from '../../helpers/enums'
import { formatDate } from '../../helpers/utilities'
import { SearchBar } from '../searchBar'

export default function CompletedOrders(props) {
  const classes = useStyles()
  const history = useHistory()

  const [loading, setLoading] = useState(true)
  const [orders, setOrders] = useState([])

  const [filteredOrders, setFilteredOrders] = useState([])
  const [search, setSearch] = useState('')
  const [orderBy, setOrderBy] = useState()
  const [direction, setDirection] = useState()

  const columns = [
    { flex: 1, field: 'name', headerName: 'Navn', renderCell: (x) => <Box fontWeight={'bold'}>{x.row.orderName || x.row.id}</Box> },
    { flex: 1, field: 'purchaseNumber', headerName: 'PO-nummer', valueGetter: (x) => x.row.purchaseNumber || '--' },
    { flex: 1, field: 'supplier', headerName: 'Leverandør', valueGetter: (x) => x.row.offer.supplier.name },
    { flex: 1, field: 'orderSize', headerName: 'Ordrestørrelse', valueGetter: (x) => `${x.row.orderSize} stk.` },
    {
      flex: 1, field: 'total', headerName: 'Pris', renderCell: (x) => <NumberFormat
        value={Number(x.row.offer.total)}
        displayType={'text'}
        thousandSeparator={'.'}
        decimalSeparator={','}
        suffix={' DKK'}
        decimalScale={2}
      />
    },
    { flex: 1, field: 'orderDate', headerName: 'Ordredato', valueGetter: (x) => formatDate(new Date(x.row.createdAt), 'd LLL') },
    { flex: 1, field: 'deliveryDate', headerName: 'Leveringsdato', valueGetter: (x) => formatDate(new Date(x.row.offer.deliveryDate), 'd LLL') },
    {
      flex: 1, field: 'messages', headerName: 'Beskeder', renderCell: (x) => <>
        {x.row.offer.order_request.order_request_comments.length > 0 ?
          <Stack gap={1} direction="row">
            <Typography>Ny besked</Typography>
            {<MessageIcon />}
          </Stack>
          :
          <Typography>--</Typography>
        }</>
    },
  ]

  useEffect(() => {
    async function getOrders() {
      setLoading(true)
      const data = await orderApi.getOrders(OrderStatus.COMPLETED)
      if (data) {
        setOrders(data.rows)
      }
      setLoading(false)
    }
    setOrderBy("createdAt")
    setDirection("desc")
    getOrders()
  }, [])

  useEffect(() => {
    let clone = lodash.cloneDeep(orders)
    let _search = search.toLowerCase()

    if (_search) {
      clone = clone.filter(c =>
        c.orderName.toLowerCase().includes(_search) ||
        c.id === Number(_search) ||
        c.purchaseNumber.toLowerCase().includes(_search) ||
        c.offer.supplier.name.toLowerCase().includes(_search)
      )
    }

    if (orderBy) {
      if (direction === 'asc') {
        clone = clone.sort((a, b) => lodash.get(a, orderBy) < lodash.get(b, orderBy) ? -1 : 1)
      } else {
        clone = clone.sort((a, b) => lodash.get(a, orderBy) > lodash.get(b, orderBy) ? -1 : 1)
      }
    }

    setFilteredOrders(clone)
  }, [orders, search, orderBy, direction])

  return (
    <React.Fragment>

      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <SearchBar search={setSearch} />
      </Box>

      <Box sx={{ backgroundColor: 'white', width: '100%' }}>
        <DataGrid
          rows={filteredOrders}
          columns={columns}
          autoHeight={true}
          pageSize={25}
          rowsPerPageOptions={[10, 25, 50, 100]}
          disableColumnMenu={true}
          disableSelectionOnClick={true}
          onRowClick={(x) => history.push(`/order/${x.row.id}/${x.row.offer.id}`)}
          loading={loading}
          localeText={daDK.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>

      {!loading && filteredOrders.length === 0 &&
        <Box className={classes.noRequests}>
          <Typography variant='h4'>
            Du har ingen accepterede tilbud.
          </Typography>
        </Box>}

    </React.Fragment>
  )
}

const useStyles = makeStyles({
  orderTable: {
    width: '100%',
    borderCollapse: 'collapse',
    background: 'white'
  },
  tableHeader: {
    backgroundColor: '#061B37',
  },
  tableRow: {
    cursor: 'pointer'
  },
  circle: {
    width: '19px',
    height: '19px',
    borderRadius: '100%'
  },
  noRequests: {
    width: '100%',
    height: '400px',
    background: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  }
})