import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Box, Grid, Typography, Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect } from "react";
import { LOGIN_URL } from "../../api";
import logo from "../../assets/images/logo.svg";
import * as userApi from "../../api/user"

function GuestHeader() {
  const classes = useStyles();

  useEffect(() => {
    const getUserInfo = async () => {
      const data = await userApi.getMe()
      if (data && data.email) {
        window.location.replace(`/neworder`)
      }
    }

    getUserInfo()
  }, [])

  const logon = () => {
    window.location.replace(`${LOGIN_URL}/authorize/customer`)
  };

  return (
    <Grid
      container
      className={classes.header}
      justifyContent="center"
      alignItems="center"
    >
      <Box className={classes.headerItems}>

        <Link href={LOGIN_URL}>
          <img src={logo} alt="logo" height="35px" />
        </Link>

        <Box className={classes.item} onClick={logon}>
          <AccountCircleIcon
            style={{ color: "#BDBDBD", marginRight: "5px" }}
          />
          <Typography style={{ color: "white", whiteSpace: "nowrap" }}>
            Log på | Opret
          </Typography>
        </Box>

      </Box>

    </Grid >
  );
}

export default GuestHeader;

const useStyles = makeStyles({
  header: {
    width: "100vw",
    height: "56px",
    position: "fixed",
    top: "0",
    zIndex: "3",
    background: "linear-gradient(90deg, #061B37 12.6%, #123667 100%)",
  },
  headerItems: {
    width: "100%",
    maxWidth: "1440px",
    padding: '0 30px',
    display: "flex",
    justifyContent: "space-between"
  },
  menuItems: {
    marginLeft: "0",
    display: "flex",
    flexDirection: "row",
  },
  item: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
    letterSpacing: "1px",
    cursor: "pointer",
    fontFamily: "Montserrat",
  },
});
