import makeStyles from '@mui/styles/makeStyles';

export const componentStyles = makeStyles({
  container: {
    backgroundColor: 'white',
    padding: '20px',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.20)',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column'
  },
  shadow: {
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.20)',
    borderRadius: '5px',
  }
})