import {
  Box, Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { formatDate } from '../../helpers/utilities'



export default function DeliveryDate(props) {
  const classes = useStyles()

  return (
    <Box className={classes.container} px={2}>
      <Typography variant='h5'>
        Leveringsdato
      </Typography>

      <Box mb={2}>
        <Typography variant='h6'>
          {formatDate(new Date(props.deliveryDate), 'PP')}
        </Typography>
      </Box>

      <Typography variant='h5'>
        Reference
      </Typography>

      <Box>
        <Typography variant='h6'>
          {props.reference}
        </Typography>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '50%',
    minHeight: '185px',
    border: '2px solid #DD8500',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  }
});