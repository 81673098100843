import {
  Box, Checkbox, CircularProgress, FormControlLabel, Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { componentStyles } from '../styles'
import * as userApi from '../../api/user'
import * as newsletterApi from '../../api/newsletter'

const emails = [{
  id: 'requestEmail',
  description: 'Send mail med opsummering af forespørgsel'
}, {
  id: 'offerEmail',
  description: 'Send mail når et tilbud modtages fra en producent'
}, {
  id: 'orderRequestRejectedEmail',
  description: 'Send mail når en producent afviser at sende et tilbud'
}, {
  id: 'offerDeadlineReminderEmail',
  description: 'Send mail 3 dage før en tilbudsfrist udløber'
}]

export default function EmailSettings() {
  const classes = useStyles()
  const _classes = componentStyles()

  const [settings, setSettings] = useState({})
  const [loading, setLoading] = useState(true)

  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isNewsletterLoading, setIsNewsletterLoading] = useState(true)

  useEffect(() => {
    async function get() {
      userApi.getMe()
        .then(function (data) {
          return newsletterApi.getNewsletterContactInfo(data);
        }).then(function (data) {
          setIsSubscribed(true)
        }).catch(error => {
          setIsSubscribed(false)
        }).finally(() => {
          setIsNewsletterLoading(false)
        });

      const data = await userApi.getSettings()

      if (data) {
        setSettings(data)
        setLoading(false)
      }
    }

    get()
  }, [])

  useEffect(() => {
    userApi.updateSettings(settings)
  }, [settings])

  const onNewsletterChanged = (value) => {
    setIsNewsletterLoading(true);

    userApi.getMe()
      .then(function (data) {
        if (value) {
          return newsletterApi.signupNewsletter(data)
        } else {
          return newsletterApi.unsubscribeNewsletter(data);
        }
      }).then(result => {
        setIsSubscribed(!isSubscribed);
      }).finally(() => {
        console.log("yeeees");
        setIsNewsletterLoading(false);
      });
  }

  return (
    <Box className={clsx(_classes.container, classes.container)}>

      <Typography variant='h5'>
        Generelle mails
      </Typography>

      <Box mb={2} mt={1}>
        {isNewsletterLoading &&
          <CircularProgress />
        }
        {!isNewsletterLoading &&
          <FormControlLabel
            control={
              <Checkbox
                checked={isSubscribed}
                onChange={(event) => { onNewsletterChanged(event.target.checked) }}
                color="primary"
              />
            }
            label={"Nyhedsbrev"}
            style={{ width: '100%' }}
          />
        }
      </Box>

      <Typography variant='h5'>
        Mails vedrørende dine ordre
      </Typography>

      {loading &&
        <Box mt={2}>
          <CircularProgress />
        </Box>}

      {!loading && <Box mt={1}>
        {emails.map(email => {
          return (
            <FormControlLabel key={email.id}
              control={
                <Checkbox
                  checked={Boolean(settings[email.id])}
                  onChange={(event) => { setSettings({ ...settings, [email.id]: event.target.checked }) }}
                  name={email.id}
                  color="primary"
                />
              }
              label={email.description}
              style={{ width: '100%' }}
            />
          )
        })}
      </Box>}

    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '60%',
    display: 'flex',
  }
});