import React, { useState } from 'react'
import clsx from 'clsx'

import makeStyles from '@mui/styles/makeStyles'
import { Dialog, Box, Button, Typography, Stack, FormControl, RadioGroup, FormControlLabel, Radio, TextField } from '@mui/material'

import { CustomRadio, CustomRadioChecked, dialogStyles } from './styles'


const ratings = ['1', '2', '3', '4', '5', '6', '7']

export default function FeedbackDialog(props) {
  const _classes = dialogStyles()
  const classes = useStyles()

  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [description, setDescription] = useState('')
  const [rating, setRating] = useState('7')
  const [finished, showFinished] = useState(false)

  const sendFeedback = () => {
    if (isButtonDisabled) return
    setIsButtonDisabled(true)

    props.sendFeedback(rating, description)
    showFinished(true)
  }

  return (
    <Dialog open={props.open} onClose={props.close} maxWidth='sm'>
      <Box className={clsx(_classes.dialog, classes.dialog)}>

        <Stack gap={4}>
          <Typography variant='h3'>
            Feedback
          </Typography>

          {finished && <>
            <Typography>
              Mange tak for din besvarelse
            </Typography>

            <Typography>
              Dine input hjælper os med at gøre Supplybase endnu bedre.
            </Typography>

            <Box alignSelf={'flex-end'}>
              <Button variant='contained' onClick={() => props.close()}>
                Afslut
              </Button>
            </Box>
          </>}

          {!finished && <>
            <Typography px={2}>
              Hvor god var din oplevelse med at indhente tilbud på Supplybase på en skala fra 1-7?
            </Typography>

            <FormControl component="fieldset" sx={{ alignSelf: 'center' }}>
              <RadioGroup name={"itemType"} value={rating} onChange={(e) => setRating(e.target.value)} row>
                {React.Children.toArray(
                  ratings.map(rating =>
                    <FormControlLabel
                      sx={{ m: 0 }}
                      labelPlacement='top'
                      value={rating}
                      control={<Radio
                        icon={<CustomRadio />}
                        checkedIcon={<CustomRadioChecked />}
                      />}
                      label={rating} />
                  )
                )}
              </RadioGroup>
            </FormControl>

            <Box textAlign={'left'}>
              <Typography variant='h4' mb={2}>
                Er der noget vi kan gøre bedre?
              </Typography>

              <TextField
                sx={{ backgroundColor: 'white' }}
                variant="outlined"
                value={description}
                fullWidth
                onChange={(e) => setDescription(e.target.value)}
                multiline
                rows={5}
              />
            </Box>

            <Box alignSelf={'flex-end'}>
              <Button variant='contained' onClick={() => sendFeedback()}>
                Fortsæt
              </Button>
            </Box>
          </>}
        </Stack>

      </Box>
    </Dialog>
  )
}

const useStyles = makeStyles({
  dialog: {
    padding: '20px',
    textAlign: 'center',
    width: '100%'
  },
})