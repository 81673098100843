import {
  Box, Button, TextField, Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { useContext, useEffect, useState } from 'react'
import * as companyApi from '../../api/company'
import { CompanyContext } from '../../contexts/companyContext'
import { componentStyles } from '../styles'
import { useQuery } from '../../helpers/hooks'

export default function CompanySettings(props) {
  const classes = useStyles()
  const _classes = componentStyles()

  const company = useContext(CompanyContext)
  const query = useQuery()

  const [edit, setEdit] = useState(query.get('edit') ? true : false)
  const [err, showErr] = useState(false)
  const [values, setValues] = useState({
    name: '',
    cvr: '',
    address: '',
    city: '',
    postal: '',
    phone: '',
    invoiceMail: ''
  })

  useEffect(() => {
    if (company.info.name) {
      setValues(company.info)
    }
  }, [company.info])

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  }

  const save = async () => {
    if (Object.values(values).filter(x => !x).length > 0) {
      showErr(true)
      return
    }

    await companyApi.update(values)
    company.getCompanyInfo()
    setEdit(false)
  }

  const renderInfo = (name, value) => {
    return <Box mb={3} mr={10}>
      <Box>
        <Typography style={{ fontWeight: 'bold' }}>
          {name}:
        </Typography>
      </Box>
      <Box>
        <Typography>
          {value}
        </Typography>
      </Box>
    </Box>
  }

  return (
    <Box className={clsx(_classes.container, classes.container)} mr={3}>

      <Typography variant='h5'>
        Virksomhedsoplysninger
      </Typography>

      {!edit &&
        <Box mt={3}>
          {renderInfo('Virksomhed', values.name)}
          {renderInfo('CVR', values.cvr)}
          {renderInfo('Adresse', values.address)}

          <Box display='flex'>
            {renderInfo('Postnr.', values.postal)}
            {renderInfo('By', values.city)}
          </Box>

          {renderInfo('Telefonnr.', values.phone)}
          {renderInfo('Email til fakturaer', values.invoiceMail)}

          <Box mt={2}>
            <Button variant='contained' onClick={() => setEdit(true)}>
              Rediger
            </Button>
          </Box>
        </Box>
      }

      {edit && <Box mt={3}>

        <Box>
          <TextField
            name={'name'}
            label="Virksomhed"
            variant="outlined"
            fullWidth
            required
            value={values.name}
            onChange={handleChange}
            error={err && !values.name}
          />
        </Box>

        <Box mt={2}>
          <TextField
            name={'cvr'}
            label="CVR"
            variant="outlined"
            fullWidth
            required
            value={values.cvr}
            onChange={handleChange}
            error={err && !values.cvr}
          />
        </Box>

        <Box mt={2}>
          <TextField
            name={'address'}
            label="Adresse"
            variant="outlined"
            fullWidth
            required
            value={values.address}
            onChange={handleChange}
            error={err && !values.address}
          />
        </Box>

        <Box display='flex' mt={2}>
          <TextField
            name={'city'}
            style={{ marginRight: '30px' }}
            label="By"
            variant="outlined"
            fullWidth
            required
            value={values.city}
            onChange={handleChange}
            error={err && !values.city}
          />

          <TextField
            name={'postal'}
            label="Postnr."
            variant="outlined"
            required
            value={values.postal}
            onChange={handleChange}
            error={err && !values.postal}
          />
        </Box>

        <Box mt={2}>
          <TextField
            name={'phone'}
            label="Telefonnr."
            variant="outlined"
            fullWidth
            required
            value={values.phone}
            onChange={handleChange}
            error={err && !values.phone}
          />
        </Box>

        <Box mt={2}>
          <TextField
            name={'invoiceMail'}
            label="Email til fakturaer"
            variant="outlined"
            fullWidth
            required
            value={values.invoiceMail}
            onChange={handleChange}
            error={err && !values.invoiceMail}
          />
        </Box>

        <Box mt={2} display='flex' justifyContent={'flex-end'} >
          <Button variant='contained' onClick={save}>
            Gem
          </Button>
        </Box>
      </Box>}

    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '50%',
    maxHeight: '90vh',
    height: 'fit-content'
  },
});