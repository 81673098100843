import React from 'react'
import { Dialog, Button, Typography, Stack, Box } from '@mui/material'
import CheckIcon from '../../assets/images/check.svg'

export default function MultipleMethodsDialog(props) {

  return (
    <Dialog open={props.open}  >
      <Stack gap={4} sx={container} textAlign='center'>
        <Typography variant='h4'>
          Bemærk!
        </Typography>

        <Typography>
          Du har valgt flere produktionsmetoder.
        </Typography>

        <Typography>
          Har du f.eks. valgt "Laserskæring" og "Vandskæring" sendes din henvendelse kun til de få producenter der både tilbyder laserskæring og vandskæring.
        </Typography>

        <Box sx={{ backgroundColor: 'white', width: '100%', color: 'black', display: 'flex', flexDirection: 'column', alignSelf: 'center', py: 2, borderRadius: 1 }}>
          <Box alignSelf='center'>
            <Box display='flex' alignItems='center' mb={1}>
              <img src={CheckIcon} alt='' style={{ marginRight: '10px', width: '18px', height: '18px' }} />
              <Typography variant='h6'>{props.methods[0].method.name}</Typography>
            </Box>
            <Box display='flex' alignItems='center'>
              <img src={CheckIcon} alt='' style={{ marginRight: '10px', width: '18px', height: '18px' }} />
              <Typography variant='h6'>{props.methods[1].method.name}</Typography>
            </Box>
          </Box>
        </Box>

        <Stack direction={'row'} justifyContent={'space-between'} sx={{ width: '100%' }}>
          <Button variant='contained' color='secondary' onClick={() => props.close()}>
            Tilbage
          </Button>

          <Button variant='contained' onClick={() => props.close()}>
            Fortsæt
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

const container = {
  background: 'radial-gradient(#102D53 0%, #061B37 100%)',
  boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.25)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  color: 'white'
}