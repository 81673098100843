import React, { createContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as companyApi from "../api/company";

export const CompanyContext = createContext()

export const CompanyProvider = ({ children }) => {
  const [info, setInfo] = useState({})
  const history = useHistory()

  useEffect(() => {
    getCompanyInfo(true)
    // eslint-disable-next-line
  }, [])

  const getCompanyInfo = async (checkSettings) => {
    const data = await companyApi.getMe()
    if (data) {
      setInfo(data)

      if (checkSettings && (!data.name || !data.phone || !data.invoiceMail)) {
        history.push('/settings?edit=true')
      }
    }

  }

  return (
    <CompanyContext.Provider
      value={{
        info,
        getCompanyInfo
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};