import clsx from 'clsx'

import {
  Box, Button, Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { componentStyles } from '../styles'

export default function OpenOrDirect(props) {
  const _classes = componentStyles()
  const classes = useStyles()

  return (
    <Box className={clsx(_classes.container, classes.container)}>
      <Typography variant='h3'>
        INDHENT TILBUD
      </Typography>

      <Box className={classes.divider} my={3} />

      <Typography variant='h4'>
        Vælg fremgangsmåde
      </Typography>

      <Box className={classes.optionsContainer} mt={3}>

        <Box className={classes.option}>
          <Typography>
            Jeg vil lade interesserede producenter se og byde på min ordre.
          </Typography>
          <Button variant='contained' sx={{ mt: '8px' }} onClick={() => props.setRFQType('open')}>
            Åben forespørgsel
          </Button>
        </Box>

        <Box className={classes.option}>
          <Typography>
            Jeg vil selv vælge 3 producenter at indhente tilbud fra.
          </Typography>
          <Button variant='contained' onClick={() => props.setRFQType('direct')}>
            Vælg 3 tilbudsgivere
          </Button>
        </Box>

      </Box>
    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    margin: '50px auto',
    width: '600px',
    display: 'flex',
    alignItems: 'center',
    padding: '24px',
  },
  divider: {
    height: '6px',
    width: '100px',
    background: '#DD8500'
  },
  optionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  option: {
    width: '250px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center'
  }
});