import React, { useState } from 'react'
import clsx from 'clsx'

import makeStyles from '@mui/styles/makeStyles';
import {
  Box,
  InputBase
} from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'

export function SearchBar(props) {
  const classes = useStyles()
  const [_value, setValue] = useState('')

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.target.blur()
    }
  }

  const setSearch = (e) => {
    props.search('search', _value)
  }

  return (
    <Box className={clsx(classes.searchBar, classes.customBox)}>
      <InputBase
        className={classes.searchInput}
        value={_value}
        onChange={e => setValue(e.target.value)}
        onBlur={setSearch}
        placeholder={'Søg efter virksomhed...'}
        onKeyDown={handleKeyPress}
        endAdornment={<Box px={1} display='flex'><SearchIcon /></Box>}
      />
    </Box>
  )
}

const useStyles = makeStyles({
  searchBar: {
    height: '50px',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.20)',
    borderRadius: '5px',
    display: 'flex',
    paddingLeft: '15px',
    alignItems: 'center',
    maxWidth: '735px',
    marginBottom: '15px',
    width: '100%',
    background: 'white'
  },
  searchInput: {
    background: 'white',
    width: '100%',
    maxWidth: '735px',
    borderRadius: '5px',
  },
  removeSearch: {
    opacity: '0.5',
    fontSize: '18px',
    cursor: 'pointer'
  }
});