import React from 'react'
import clsx from 'clsx'

import makeStyles from '@mui/styles/makeStyles'
import {
  Dialog,
  Box,
  Button,
  Typography
} from '@mui/material'

import { dialogStyles } from './styles'

export default function CancelEditDialog(props) {
  const _classes = dialogStyles()
  const classes = useStyles()

  return (
    <Dialog open={props.open} onClose={props.close} maxWidth='sm'>
      <Box className={clsx(_classes.dialog, classes.dialog)}>

        <Box mb={3}>
          <Typography variant='h4'>
            Vil du annullere dine rettelser?
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography>
            Dine rettelser bliver ikke gemt hvis du fortsætter
          </Typography>
        </Box>

        <Box display='flex' justifyContent='space-between' width='100%'>
          <Button variant='contained' color='secondary' onClick={() => props.close()}>
            Tilbage
          </Button>

          <Button variant='contained' onClick={() => props.cancelEdit()}>
            Fortsæt
          </Button>
        </Box>

      </Box>
    </Dialog>
  )
}

const useStyles = makeStyles({
  dialog: {
    padding: '20px',
  },
})