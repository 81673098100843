import { createTheme } from '@mui/material/styles';

export const mainTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#DD8500',
      contrastText: "#fff"
    },
    secondary: {
      main: '#808080',
      contrastText: "#fff"
    },
    red: {
      main: '#E73030',
      contrastText: "#fff"
    },
    text: {
      primary: '#242729'
    }
  },
  typography: {
    fontFamily: 'system-ui',
    fontSize: 14,
    h1: {},
    h2: {},
    h3: {
      fontSize: '24px',
      fontFamily: 'Montserrat'
    },
    h4: {
      fontSize: '19px',
      fontFamily: 'Montserrat'
    },
    h5: {
      fontSize: '19px',
      fontFamily: 'Montserrat'
    },
    h6: {
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '22px'
    },
    subtitle1: {
      fontSize: '13px',
    },
    subtitle2: {
      fontSize: '14px',
      fontStyle: 'italic',
      fontWeight: '400'
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          margin: '10px'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '14px',
          maxWidth: '1050px',
          backgroundColor: '#061B37'
        }
      }
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          borderRadius: '0'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            '-webkit-box-shadow': '0 0 0 100px #FFF inset',
            '-webkit-text-fill-color': '#000'
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 0,
          textTransform: 'none',
          minWidth: '0px !important',
          minHeight: '40px',
          marginRight: '50px',
          fontSize: '19px',
          fontFamily: 'Montserrat',
          alignItems: 'flex-start'
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '30px',
          borderBottom: '2px solid #C7C7C7'
        },
        indicator: {
          height: '5px',
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderBottom: '2px solid #C7C7C7',
          "&$hover:hover": {
            backgroundColor: '#FFF0D9'
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          textAlign: 'center',
          padding: '6px 16px'
        },
        head: {
          padding: '10px'
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        columnHeaders: {
          backgroundColor: '#061B37',
        },
        columnHeader: {
          backgroundColor: '#061B37',
          color: 'white'
        },
        sortIcon: {
          color: 'white'
        },
        row: {
          cursor: 'pointer'
        }
      }
    }
  },
})
