import React from 'react'
import { Typography, Box, Stack } from '@mui/material'
import TagIcon from '@mui/icons-material/Tag';

export default function OfferNumber(props) {
  return (
    <Box display='flex' sx={{ justifyContent: 'space-between' }}>
      <Stack gap={1} direction='row'>
        <TagIcon />
        <Typography variant='h6'>Referencenummer:</Typography>
      </Stack>
      <Typography >{!props.offerNumber ? 'Ikke udfyldt' : props.offerNumber}</Typography>
    </Box>
  )
}