import { Box, Typography, Stack, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { companyNameToURLString } from '../../helpers/utilities'

export default function SupplierInfo(props) {
  const classes = useStyles()
  const { supplier } = props

  const renderInfo = (name, value) => {
    return <Box display='flex'>
      <Box width='160px'>
        <Typography style={{ fontWeight: 'bold' }}>
          {name}:
        </Typography>
      </Box>
      <Box width='50%'>
        <Typography>
          {value}
        </Typography>
      </Box>
    </Box>
  }

  return (
    <Stack className={classes.container} gap={0.5}>
      {renderInfo('Virksomhed', supplier.name)}
      {renderInfo('CVR', supplier.cvr)}
      {renderInfo('Tlf', supplier.phone)}
      {renderInfo('Addresse', `${supplier.address}, ${supplier.postal} ${supplier.city}`)}
      {renderInfo('Mail', supplier.email)}

      <Box mt={2} display="flex" justifyContent="center" alignItems="center">
        <Button 
          component="a" 
          href={`https://supplybase.dk/virksomheder/${companyNameToURLString(supplier.name)}`} target="_blank" 
          variant='contained'
        >
          Vis profil
        </Button>
      </Box>
    </Stack >
  )
}

const useStyles = makeStyles({
  container: {
    borderRadius: '5px',
    backgroundColor: 'white',
    width: '100%',
  },
  divider: {
    height: '2px',
    background: '#C7C7C7',
    margin: '16px 0 8px'
  }
});