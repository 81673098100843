import lodash from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

import {
  Box, Button, Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import Chat from '@mui/icons-material/Chat';
import { daDK, DataGrid } from '@mui/x-data-grid';
import * as orderApi from '../../api/order';
import { OrderStatus } from '../../helpers/enums';
import { dayDifference, formatDate } from '../../helpers/utilities';
import { SearchBar } from '../searchBar';

export default function OrderList(props) {
  const classes = useStyles()
  const history = useHistory()

  const [loading, setLoading] = useState(true)
  const [orders, setOrders] = useState([])

  const [filteredOrders, setFilteredOrders] = useState([])
  const [search, setSearch] = useState('')
  const [orderBy, setOrderBy] = useState()
  const [direction, setDirection] = useState()

  const columns = [
    { flex: 1, field: 'orderName', headerName: 'Navn', renderCell: (x) => <Box fontWeight={'bold'}>{x.row.orderName || x.row.id}</Box> },
    { flex: 1, field: 'orderSize', headerName: 'Antal', valueGetter: (x) => `${x.row.orderSize} stk.` },
    { flex: 1, field: 'createdAt', headerName: 'Ordredato', valueGetter: (x) => formatDate(new Date(x.row.createdAt), 'd LLL') },
    { flex: 1, field: 'offerExpire', headerName: 'Tilbudsfrist', valueGetter: (x) => formatDate(new Date(x.row.offerExpire), 'd LLL') },
    { flex: 1, field: 'messages', headerName: 'Beskeder', renderCell: (x) => renderMsgStatus(x.row) },
    { flex: 1, field: 'offers', headerName: 'Tilbud', valueGetter: (x) => x.row.suppliers?.filter(s => s.offer).length || 0 },
    { flex: 1, field: 'status', headerName: 'Status', renderCell: (x) => renderStatus(x.row) },
    {
      flex: 1, field: 'action', headerName: '', renderCell: (x) => <Button variant='contained' onClick={() => history.push(`/offers/${x.row.id}`)}>
        Vis tilbud
      </Button>
    },
  ]

  useEffect(() => {
    async function getOrders() {
      setLoading(true)
      const data = await orderApi.getPreviousOrders()

      if (data) {
        setOrders(data.rows)
      }

      setLoading(false)
    }

    setOrderBy("createdAt")
    setDirection('desc')

    getOrders()
  }, [])

  useEffect(() => {
    let clone = lodash.cloneDeep(orders)
    let _search = search.toLowerCase()

    if (_search) {
      clone = clone.filter(c =>
        c.orderName.toLowerCase().includes(_search) ||
        c.id === Number(_search)
      )
    }

    if (orderBy) {
      if (direction === 'asc') {
        clone = clone.sort((a, b) => lodash.get(a, orderBy) < lodash.get(b, orderBy) ? -1 : 1)
      } else {
        clone = clone.sort((a, b) => lodash.get(a, orderBy) > lodash.get(b, orderBy) ? -1 : 1)
      }
    }

    setFilteredOrders(clone)
  }, [orders, search, orderBy, direction])

  const renderStatus = (order) => {
    if ([OrderStatus.BENCHMARKED, OrderStatus.COMPLETED, OrderStatus.CLOSED].includes(order.status)) return 'Afsluttet'

    if (order.status === OrderStatus.EXPIRED) return 'Vælg tilbud'

    const difference = dayDifference(new Date(order.offerExpire), new Date())
    if (difference === 1) {
      return '1 dag tilbage'
    } else if (difference === 0) {
      return 'Udløber idag'
    } else {
      return `${difference} dage tilbage`
    }
  }

  const renderMsgStatus = (order) => {
    let messages = order.suppliers?.filter(s => s.order_request_comments?.length > 0).length

    if (messages === 0) {
      return 'Ingen nye'
    }

    if (messages === 1) {
      return <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-evenly'> <Chat style={{ fill: '#db8500' }} /> 1 ny besked</Box >
    }

    return <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-evenly'> <Chat style={{ fill: '#db8500' }} /> {messages} nye beskeder</Box >
  }

  return (
    <React.Fragment>

      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <SearchBar search={setSearch} />
      </Box>

      <Box sx={{ backgroundColor: 'white', width: '100%' }}>
        <DataGrid
          rows={filteredOrders}
          columns={columns}
          autoHeight={true}
          pageSize={25}
          rowsPerPageOptions={[10, 25, 50, 100]}
          disableColumnMenu={true}
          disableSelectionOnClick={true}
          onRowClick={(x) => history.push(`/offers/${x.row.id}`)}
          loading={loading}
          localeText={daDK.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>

      {!loading && filteredOrders.length === 0 &&
        <Box className={classes.noRequests}>
          <Typography variant='h4'>
            Du har ingen aktive forespørgsler.
          </Typography>
          <br />
          <Typography variant='h4'>
            Tryk '+Indhent tilbud' i sidemenuen for at oprette en forespørgsel på tilbud.
          </Typography>
        </Box>
      }

    </React.Fragment>
  )
}

const useStyles = makeStyles({
  orderTable: {
    width: '100%',
    borderCollapse: 'collapse',
    background: 'white'
  },
  tableHeader: {
    backgroundColor: '#061B37',
  },
  tableRow: {
    cursor: 'pointer'
  },
  circle: {
    width: '19px',
    height: '19px',
    borderRadius: '100%'
  },
  noRequests: {
    width: '100%',
    height: '400px',
    background: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  }
})