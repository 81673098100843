import {
  Box, Button, Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'
import { componentStyles } from '../styles'
import CheckIcon from '../../assets/images/check.svg'
import { useHistory } from 'react-router-dom'

export default function SupplierOverview(props) {
  const classes = useStyles()
  const _classes = componentStyles()
  const history = useHistory()

  const { orderId, requirements, rfqType, orderRequests } = props

  return (
    <Box className={clsx(_classes.container, classes.container)}>

      <Typography variant='h5'>
        Din forespørgsel er sendt til følgende producenter
      </Typography>

      {rfqType === 'open' && <>
        <Box my={2}>
          <Typography>
            Produktionsvirksomheder med søgefiltrene:
          </Typography>
        </Box>

        <Box>
          {React.Children.toArray(
            requirements.map(filter => <Box display='flex' alignItems='center' justifyContent={'space-between'} mb={2}>

              <Box display='flex' alignItems='center'>
                <img src={CheckIcon} alt='' style={{ marginRight: '8px', width: '18px', height: '18px' }} />
                <Typography>
                  {filter.name.split(' (')[0]}
                </Typography>
              </Box>

            </Box>)
          )}
        </Box>
      </>}

      {rfqType === 'direct' && <>
        <Box my={2}>
          <Typography fontSize={'14px'} fontWeight='500'>
            {`Du har valgt ${orderRequests.length} tilbudsgiver(e)`}
          </Typography>
        </Box>

        <Box>
          {React.Children.toArray(
            orderRequests.map(orderRequest => <Box display='flex' alignItems='center' justifyContent={'space-between'} mb={2}>

              <Box display='flex' alignItems='center'>
                <Box mr={2}>
                  <Typography>
                    {orderRequest.supplier.name}
                  </Typography>
                </Box>
              </Box>

            </Box>)
          )}
        </Box>
      </>}

      <Box mt={2}>
        <Typography variant='h4'>Se din forespørgsel</Typography>
      </Box>

      <Box mt={2}>
        <Typography>
          Gå til din forespørgsel for at se og opdatere sendte filer
        </Typography>
      </Box>

      <Box mt={3}>
        <Button variant='contained' onClick={() => history.push(`/orders?orderId=${orderId}&offerId=${orderId}`)}>
          Vis forespørgsel
        </Button>
      </Box>

    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '242px',
    minWidth: '242px'
  }
});