import React, { useState } from 'react'
import clsx from 'clsx'

import makeStyles from '@mui/styles/makeStyles';
import {
  Dialog,
  Typography,
  Box,
  Button,
  Stack
} from '@mui/material'

import { dialogStyles } from './styles'
import * as userApi from '../../api/user'
import { LOGIN_URL } from '../../api';

export default function CheckMailDialog(props) {
  const _classes = dialogStyles()
  const classes = useStyles()

  const [isTimedOut, setIsTimedOut] = useState(false)

  const onResendMail = () => {
    setIsTimedOut(true)
    userApi.resendVerification(props.email)
  }

  return (
    <Dialog open={props.open} maxWidth='xl'>
      <Stack justifyContent="center" spacing={4} className={clsx(_classes.dialog, classes.dialog)}>

        <Typography variant='h4' align='center'>
          Tjek din mail
        </Typography>

        <Typography align='center'>
          Vi har sendt en mail til din email adresse. Benyt linket i din mail til at aktivere din profil på Supplybase
        </Typography>

        <Typography variant='h6' align='center'>
          Har du ikke modtaget en mail?
        </Typography>

        <Box display='flex' alignSelf='center'>
          <Button variant='contained' size='large' disabled={isTimedOut} onClick={onResendMail}>
            Gensend mail
          </Button>
        </Box>

        <Box display='flex' alignSelf={'flex-end'}>
          <Button variant='contained' color='secondary' onClick={() => window.location.replace(`${LOGIN_URL}`)}>Afslut</Button>
        </Box>
      </Stack>
    </Dialog>
  )
}

const useStyles = makeStyles({
  dialog: {
    padding: '20px',
    width: '500px'
  },
})