import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import ReactGA from 'react-ga'
import daLocale from 'date-fns/locale/da'

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { CssBaseline, Box } from '@mui/material'

import Header from './components/header'
import GuestHeader from './components/guest/guestHeader'
import Me from './components/me'
import { mainTheme } from './helpers/themes'
import { routes } from './helpers/routes'
import { guestRoutes } from './helpers/guestRoutes'
import { UserProvider } from './contexts/userContext'
import { CompanyProvider } from './contexts/companyContext'
import { SnackbarProvider } from 'notistack'

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize("")
} else {
  ReactGA.initialize("")
}

function App() {
  return (
    <Router>
      <Box className='background'>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={mainTheme}>
            <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} preventDuplicate>
              <CssBaseline />
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={daLocale}>

                {window.location.pathname.includes('/guest')
                  ? <Box sx={{ position: 'absolute', zIndex: '1', width: '100%', height: '100%' }}>
                    <GuestHeader />
                    <Box sx={{ margin: 'auto', maxWidth: '1440px', height: '100%' }}>
                      <Route render={({ location }) => (
                        <Switch location={location} key={location.pathname}>
                          {guestRoutes.map((route, index) => {
                            return <Route key={index} path={route.path} component={route.content} />
                          })}
                        </Switch>
                      )} />
                    </Box>
                  </Box>

                  : <Box className='main'>
                    <UserProvider>
                      <CompanyProvider>
                        <Header />
                        <Me />
                        <Route render={({ location }) => (
                          <Switch location={location} key={location.pathname}>
                            {routes.map((route, index) => {
                              return <Route key={index} path={route.path} component={route.content} />
                            })}
                          </Switch>
                        )} />
                      </CompanyProvider>
                    </UserProvider>
                  </Box>}

              </LocalizationProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </Box>
    </Router>
  );
}

export default App
