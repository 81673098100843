import React from 'react'

import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Typography
} from '@mui/material'

import { pageStyles } from './styles'
import Wizard from '../containers/newOrder/wizard'

function NewOrder() {
  const classes = useStyles()
  const _classes = pageStyles()

  return (
    <Box className={classes.container}>
      <Box className={_classes.pageTitle}>
        <Typography variant='h3'>Indhent tilbud</Typography>
      </Box>

      <Wizard />
    </Box>
  )
}

export default NewOrder

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    paddingTop: '60px',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }
});