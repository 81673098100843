import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { Button, Dialog, FormControl, FormControlLabel, IconButton, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { Dropzone } from '../../components/dropZone'
import UploadSameFilesDialog from './uploadSameFilesDialog'

const acceptedFileTypes = ['.pdf', '.png', '.jpeg', '.jpg', '.dxf', '.dwg', '.stp', '.step', '.iges', '.stl']
const uniqueTypes = ['.dxf', '.dwg', '.stp', '.step', '.iges', '.stl']

export default function UploadFilesDialog(props) {
  const [description, setDescription] = useState('')
  const [type, setType] = useState('single')
  const [items, setItems] = useState([])
  const [amount, setAmount] = useState('')
  const [sameFilesError, showSameFilesError] = useState(false)
  const [error, setError] = useState(false)
  const { enqueueSnackbar } = useSnackbar();

  const removeFile = (file) => {
    setItems(prev => prev.filter(item => item.name !== file.name))
  }

  const uploadFiles = (files) => {
    const acceptedFiles = [...items]

    files.forEach(file => {
      file.extension = file.name.slice(file.name.lastIndexOf(".")).toLowerCase()
      file.storageId = "1"

      if (acceptedFiles.find(f => f.name === file.name)) {
        return
      }

      if (uniqueTypes.includes(file.extension) && type === 'single' && acceptedFiles.find(f => f.extension === file.extension)) {
        showSameFilesError(true)
        return
      }

      acceptedFiles.push(file)
    })

    setItems(acceptedFiles)
  }

  const add = () => {
    if (!description || !amount) {
      enqueueSnackbar("Udfyld alle felter.", {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'center' }
      })

      return setError(true)
    }

    if (items.length < 1) {
      enqueueSnackbar("Der er ingen filer tilknyttet.", {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'center' }
      })

      return
    }

    const item = {
      name: description,
      type: type,
      files: items,
      quantity: amount
    }

    props.setOrderItems(prev => [...prev, item])
    props.close()
  }

  return (
    <Dialog open={props.open} maxWidth='sm' fullWidth>
      <UploadSameFilesDialog open={sameFilesError} onClose={() => showSameFilesError(false)} />

      <Stack gap={2} sx={container}>
        <Typography variant='h3' textAlign={'center'}>
          Tilføj et emne
        </Typography>

        <Stack gap={1}>
          <Typography variant='h6'>
            Beskrivelse
          </Typography>

          <TextField
            error={error && !description}
            label="Navn"
            variant="outlined"
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Stack>

        <Stack gap={0.5}>
          <Typography variant='h6'>
            Angiv type
          </Typography>

          <FormControl component="fieldset">
            <RadioGroup name={"itemType"} value={type} onChange={(e) => setType(e.target.value)} row>
              <FormControlLabel value={'single'} control={<Radio />} label={`Enkelt emne`} />
              <FormControlLabel value={'construction'} control={<Radio />} label={`Konstruktion af flere dele (fx svejste konstruktioner)`} />
            </RadioGroup>
          </FormControl>

        </Stack>

        <Stack gap={1}>
          <Typography variant='h6'>
            {type === 'single' ? 'Tilføj enkelt emne' : 'Tilføj konstruktion'}
          </Typography>

          <Typography>
            {type === 'single' ? 'Tilføj detailtegning og eventuelt CAD- eller skærefilen for emnet.' : 'Upload samlingstegning samt alle undertegninger og filer.'}
          </Typography>

          <Dropzone uploadFile={uploadFiles} fileTypes={acceptedFileTypes.join(",")} />

          <Stack direction={'row'} gap={2} justifyContent={'flex-end'} flexWrap={'wrap'}>
            {React.Children.toArray(
              items.map(item => <Stack direction={'row'} alignItems={'center'} gap={0.5}>
                <Typography>
                  {item.name}
                </Typography>

                <IconButton onClick={() => removeFile(item)} sx={{ p: 0 }}>
                  <RemoveCircleIcon style={{ color: '#E73030' }} />
                </IconButton>
              </Stack>)
            )}
          </Stack>
        </Stack>

        <Stack gap={1}>
          <Typography variant='h6'>
            Angiv styktal
          </Typography>

          <TextField
            error={error && !amount}
            type={'number'}
            label="Styktal"
            variant="outlined"
            fullWidth
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </Stack>

        <Stack direction={'row'} justifyContent={'space-between'}>
          <Button variant='contained' color='secondary' onClick={() => props.close()}>
            Tilbage
          </Button>

          <Button variant='contained' onClick={add}>
            Tilføj
          </Button>
        </Stack>
      </Stack>

    </Dialog>
  )
}

const container = {
  background: 'white',
  boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.25)',
  display: 'flex',
  flexDirection: 'column',
  padding: '20px',
  color: 'black'
}