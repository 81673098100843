import axios from 'axios';
import { saveAs } from 'file-saver';
import { API_ENDPOINT, buildApiHeaders } from '.';

const API_ROUTE = `${API_ENDPOINT}/file`

export async function addFile(file) {
  const url = `${API_ROUTE}/add`

  const formData = new FormData()
  formData.append('file', file)

  const config = Object.assign(buildApiHeaders(), { 'content-type': 'multipart/form-data' })

  const response = await axios.post(url, formData, { headers: config })
    .catch(err => err.response)

  return response ? response.data : null
}

export async function uploadFile(file) {
  const url = `${API_ROUTE}/upload`

  const formData = new FormData()
  formData.append('file', file)

  const config = Object.assign(buildApiHeaders(), { 'content-type': 'multipart/form-data' })

  const response = await axios.post(url, formData, { headers: config })
    .catch(err => err.response)

  return response ? response.data : null
}

export async function getFile(fileName, orderItemFileId, fileId) {
  const url = `${API_ROUTE}/orderFile`;
  await axios.get(url,
    {
      params: {
        fileName: fileName,
        orderItemFileId: orderItemFileId,
        fileId: fileId
      },
      headers: buildApiHeaders(),
      responseType: "blob"
    }).then(function (response) {
      const blob = new Blob([response.data], { type: response.headers['content-type'] })
      saveAs(blob, fileName)
    })
}