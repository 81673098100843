import React, { useState, useEffect } from 'react'

import makeStyles from '@mui/styles/makeStyles';
import {
  Typography,
  Box,
  IconButton,
  InputBase,
  Checkbox,
  FormControlLabel,
  Collapse
} from '@mui/material'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import SearchIcon from '@mui/icons-material/Search'

export default function FilterGroup(props) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [groups, setGroups] = useState([])
  const [data, setData] = useState([])

  useEffect(() => {
    const filteredData = props.data.filter(x => x.name.toLowerCase().includes(search.toLowerCase()))
    const allGroups = [...new Set(filteredData.map(x => x.category))]

    setData(filteredData)
    setGroups(allGroups)
  }, [props.data, search])


  return (
    <Box className={classes.container}>

      <Box className={classes.header} onClick={() => setOpen(!open)}>
        <Typography variant='h5' style={{ color: 'white' }}>
          {props.name}
        </Typography>

        <IconButton aria-label="expand row" size="small">
          {open ? <KeyboardArrowUpIcon htmlColor='white' fontSize='large' /> : <KeyboardArrowDownIcon htmlColor='white' fontSize='large' />}
        </IconButton>
      </Box>

      <Collapse in={open} timeout="auto" unmountOnExit>
        {props.search && <Box>

          <InputBase
            className={classes.searchInput}
            value={search}
            onChange={e => setSearch(e.target.value)}
            fullWidth
            placeholder={`Søg på ${props.name.toLowerCase()}`}
            endAdornment={<Box px={1} display='flex'><SearchIcon /></Box>}
          />

        </Box>}

        <Box mt={2} maxHeight={'500px'} style={{ overflowY: 'scroll' }}>

          {groups.sort().map(group =>
            <Box key={group} className={classes.groupContainer}>
              <Typography variant='h6'>{group}</Typography>

              <Box pl={2} mb={1} pr={1}>
                {data.filter(x => x.category === group).map(x =>
                  <Box key={x.id} display='flex' justifyContent='space-between' alignItems='center'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(props.currentFilters.find(f => f === x.id))}
                          onChange={(event) => props.addFilter(x.id, event)}
                          name={x.name}
                          color="primary"
                        />
                      }
                      label={x.name}
                    />

                    <Box className={classes.number}>
                      <Typography variant='h6'>{x.count}</Typography>
                    </Box>
                  </Box>
                )}
              </Box>

            </Box>
          )}

        </Box>
      </Collapse>
    </Box >
  )
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    marginBottom: '15px'
  },
  header: {
    backgroundColor: '#061B37',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 10px',
    cursor: 'pointer'
  },
  searchInput: {
    marginTop: '10px',
    background: 'white',
    borderRadius: '5px',
    border: '1px solid #061B37',
    padding: '8px 10px'
  },
  number: {
    minWidth: '30px',
    height: '25px',
    borderRadius: '5px',
    backgroundColor: '#696969',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});