import { Box, Typography, Button, Stack } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'
import { componentStyles } from '../styles'
import SupplierOverview from '../supplierOverview'
import { Link } from 'react-router-dom';

function Companies(props) {
  const classes = useStyles()
  const _classes = componentStyles()

  return (
    <Stack gap={3} className={clsx(_classes.container, classes.container)} my={2}>

      <Typography variant='h5'>
        Du modtager tilbud fra følgende producenter
      </Typography>

      <Box display='flex' mt={2} justifyContent='center'>
        {React.Children.toArray(
          props.suppliers.map(supplier =>
            <SupplierOverview company={supplier} />
          )
        )}
      </Box>
      {!props.edit &&
        <Box display="flex" justifyContent="center" alignItems="center">
          <Button component={Link} to={`/offers/${props.orderId}?orderId=${props.orderId}`} variant='contained'>Vis tilbud</Button>
        </Box>
      }
    </Stack>
  )
}

export default Companies

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  company: {
    width: '260px',
    height: '390px',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logo: {
    maxHeight: '95%',
    maxWidth: '95%',
    margin: 'auto'
  },
});