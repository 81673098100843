import {
  Box, Button, TextField, Typography
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import React, { useState } from 'react'
import { FileTable } from '../../components/orders/fileTable'
import { Actions } from './actions'
import { containerStyles } from './styles'

import UploadFilesDialog from '../../components/dialogs/uploadFilesDialog'

export default function StepOne(props) {
  const _classes = containerStyles()
  const classes = useStyles()
  const [uploadFiles, openUploadFiles] = useState(false)

  useState(() => {

  })

  const next = async () => {
    props.cleanUpFiles()
    props.next()
  }

  return (
    <Box className={_classes.container} maxWidth={'1450px'}>
      {uploadFiles && <UploadFilesDialog
        open={uploadFiles}
        close={() => openUploadFiles(false)}
        uploadFile={props.uploadFile}
        setOrderItems={props.setOrderItems}
      />}

      <Box justifyContent='space-between' mt={1}>

        <Box className={classes.fileContainer}>

          <Box className={clsx(classes.addFilesContainer, classes.container)} mr={'2%'}>
            <Typography variant='h5'>
              Tilføj emne
            </Typography>

            <Typography variant='body' textAlign={'center'}>
              Klik her for at tilføje filer og tegninger til din forespørgsel.
            </Typography>

            <Button variant='contained' className={classes.addButton} onClick={() => openUploadFiles(true)}>
              <Typography variant='h2' fontWeight={'bold'}>+</Typography>
            </Button>
          </Box>

          <Box className={clsx(classes.fileTable)} >
            <Box minHeight='400px' className={clsx(classes.container)}>
              <FileTable
                edit={true}
                orderItems={props.orderItems}
                setOrderItems={props.setOrderItems}
                uploadFile={props.storeFile}
                removeButton
              />
            </Box>
          </Box>
        </Box>

        <Box display='flex' width='100%' justifyContent={'space-between'}>
          <Box mt={3} mr={2}>
            <Typography fontStyle='italic' fontSize='14px'>
              Tilføj kun de relevante tegninger for det pågældende emne. Har du flere forskellige tegninger af emner du ønsker tilbud på, så tilføj dem af flere omgange. Husk at kontrollere om alle de nødvendige filer er tilføjet.
            </Typography>
          </Box>

          <Box mt={2} className={classes.container} width='fit-content' minWidth={'280px'} p={2}>
            <Typography variant='h5'>
              Ordrestørrelse: {props.orderItems.reduce((a, b) => Number(a) + Number(b.quantity), 0)} stk.
            </Typography>
          </Box>
        </Box>

      </Box>

      <Box mb={1} mt={2}>
        <Typography variant='h5'>
          Kommentar til ordre
        </Typography>
      </Box>

      <Box style={{ background: 'white' }}>
        <TextField
          name="comment"
          placeholder="Skriv en kommentar til din ordre her ..."
          variant="outlined"
          value={props.values.comment || ''}
          fullWidth
          onChange={props.handleChange}
          multiline
          rows={5}
        />
      </Box>

      <Actions
        next={next}
        back={props.back}
        disabled={props.orderItems.length === 0
          || props.orderItems.reduce((a, b) => Number(a) + Number(b.quantity), 0) < 1
          || props.orderItems.filter(orderItem => orderItem.files?.length > 0) < 1}
      />

    </Box >
  )
}

const useStyles = makeStyles({
  container: {
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    backgroundColor: 'white',

  },
  fileContainer: {
    width: '100%',
    display: 'flex',
    '@media (max-width: 1350px)': {
      display: 'initial'
    }
  },
  fileTable: {
    width: '78%',
    '@media (max-width: 1350px)': {
      width: '100%',
    }
  },
  addFilesContainer: {
    padding: '20px',
    minWidth: '240px',
    width: '20%',
    minHeight: '400px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    '@media (max-width: 1350px)': {
      width: '100%',
      height: '180px',
      minHeight: '180px',
      marginBottom: 12,
      padding: '4px'
    }
  },
  addButton: {
    paddingTop: 0,
    '@media (max-width: 1350px)': {
      height: '60px',
    }
  }
});