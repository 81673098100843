import axios from 'axios'
import { CUSTOMER_ENDPOINT, buildApiHeaders } from '.'

const API_ROUTE = `${CUSTOMER_ENDPOINT}/user`

export async function getMe() {
  const url = `${API_ROUTE}/me`
  const response = await axios.get(url, { headers: buildApiHeaders() })
    .catch(err => err.response)

  return response ? response.data : null
}

export async function getSettings() {
  const url = `${API_ROUTE}/settings`

  const response = await axios.get(url, { headers: buildApiHeaders() })
    .catch(err => err.response)

  return response ? response.data : null
}

export async function updateSettings(settings) {
  const url = `${API_ROUTE}/settings`

  const response = await axios.post(url, settings, { headers: buildApiHeaders() })
    .catch(err => err.response)

  return response ? response.data : null
}

export async function update(values) {
  const url = `${API_ROUTE}/update`
  const response = await axios.post(url, values, { headers: buildApiHeaders() })
    .catch(err => err.response)

  return response ? response.data : null
}

export async function getUserList() {
  const url = `${API_ROUTE}/companyUsers`

  const response = await axios.get(url, { headers: buildApiHeaders() })
    .catch(err => err.response)

  return response ? response.data : null
}

export async function changeAdmin(userId) {
  const url = `${API_ROUTE}/changeAdmin`
  const response = await axios.post(url, { userId }, { headers: buildApiHeaders() })
    .catch(err => err.response)

  return response ? response.data : null
}

export async function inviteEmployee(values) {
  const url = `${API_ROUTE}/invite`
  const response = await axios.post(url, values, { headers: buildApiHeaders() })
    .catch(err => err.response)

  return response ? response.data : null
}

export async function resendVerification(email) {
  const url = `${API_ROUTE}/resendVerification`

  const response = await axios.post(url, { email }).catch(err => err.response)

  return response ? response.data : null
}