import axios from 'axios'
import { saveAs } from 'file-saver'
import { CUSTOMER_ENDPOINT, buildApiHeaders } from '.'

const API_ROUTE = `${CUSTOMER_ENDPOINT}/offer`

export async function getOffers(orderId) {
  const url = `${API_ROUTE}/list`
  const response = await axios.get(url, {
    params: {
      orderId
    },
    headers: buildApiHeaders()
  }).catch(err => err.response)

  return response ? response.data : null
}

export async function acceptOffer(offerId) {
  const url = `${API_ROUTE}/accept`

  return axios.post(url, {
    offerId,
  },
    {
      headers: buildApiHeaders()
    })
}

export async function getOfferFile(offerId, fileName) {
  const url = `${API_ROUTE}/offerFile`

  const response = await axios.get(url, {
    params: {
      offerId
    },
    headers: buildApiHeaders(),
    responseType: 'blob'
  }).catch(err => err.response)

  saveAs(new Blob([response.data]), fileName)
}

export async function rejectAll(orderId, reason) {
  const url = `${API_ROUTE}/rejectAll`

  const response = await axios.post(url, {
    orderId,
    reason
  }, { headers: buildApiHeaders() })
    .catch(err => err.response)

  return response ? response.data : null
}

export async function benchmarkOffers(orderId) {
  const url = `${API_ROUTE}/benchmark`

  const response = await axios.post(url, {
    orderId,
  }, { headers: buildApiHeaders() })
    .catch(err => err.response)

  return response ? response.data : null
}