import React from 'react'
import clsx from 'clsx'

import makeStyles from '@mui/styles/makeStyles'
import {
  Dialog,
  Box,
  Typography
} from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress';

import { dialogStyles } from './styles'

export default function SendingOrderDialog(props) {
  const _classes = dialogStyles()
  const classes = useStyles()
  console.log(props.progress)
  return (
    <Dialog open={props.open} maxWidth='sm'>
      <Box className={clsx(_classes.dialog, classes.dialog)}>

        <Box mb={3}>
          <Typography variant='h4'>
            Sender ordrer
          </Typography>
        </Box>
        <Box mb={3} sx={{ width: '100%' }}>
          <LinearProgress variant="determinate" value={props.progress} />
        </Box>
      </Box>
    </Dialog>
  )
}

const useStyles = makeStyles({
  dialog: {
    padding: '20px',
  },
})