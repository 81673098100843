import React from 'react'

import makeStyles from '@mui/styles/makeStyles'
import {
  Box, Typography
} from '@mui/material'

import * as offerApi from '../../api/offer'

export default function AttachedFiles(props) {
  const classes = useStyles()

  const onClick = () => {
    if (props.isNotAttached) return
    offerApi.getOfferFile(props.offerId, props.name)
  }

  return (
    <Box className={classes.container} mr={3} p={2}>
      <Typography variant='h5'>
        Tilbud
      </Typography>

      <Box mt={1} onClick={onClick} >
        {props.isNotAttached &&
          <Typography>
            Ikke vedhæftet
          </Typography>
        }
        {!props.isNotAttached &&
          <Typography style={{ textDecoration: 'underline', color: '#7B61FF', cursor: 'pointer' }}>
            {props.name}
          </Typography>
        }
      </Box>

    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '50%',
    minHeight: '185px',
    border: '2px solid #DD8500',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  }
});