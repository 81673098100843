import {
  Box, Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'
import { componentStyles } from '../styles'
import CheckIcon from '../../assets/images/check.svg'

function ReqSummary(props) {
  const classes = useStyles()
  const _classes = componentStyles()

  const { requirements, supplierMethods, supplierMaterials, supplierCertifications } = props

  const renderChosenFilters = (filters, all) => {
    return <>
      {React.Children.toArray(
        filters.map(filter => <Box display='flex' alignItems='center' justifyContent={'space-between'} mt={1}>

          <Box display='flex' alignItems='center'>
            <img src={CheckIcon} alt='' style={{ marginRight: '8px', width: '18px', height: '18px' }} />
            <Box mr={2}>
              <Typography variant='h6'>
                {all.find(a => a.id === filter)?.name}
              </Typography>
            </Box>
          </Box>

        </Box>)
      )}
    </>
  }

  const renderOrderFilters = () => {
    return <Box display='flex' mt={2} justifyContent='center' flexWrap={'wrap'} mb={1}>
      {React.Children.toArray(
        requirements.map(filter => <Box display='flex' alignItems='center' justifyContent={'space-between'} mt={1}>

          <Box display='flex' alignItems='center'>
            <img src={CheckIcon} alt='' style={{ marginRight: '8px', width: '18px', height: '18px' }} />
            <Box mr={2}>
              <Typography variant='h6'>
                {filter.name.split(' (')[0]}
              </Typography>
            </Box>
          </Box>

        </Box>)
      )}
    </Box>
  }

  return (
    <Box className={clsx(_classes.container, classes.container)} my={2}>

      <Typography variant='h5'>
        Du modtager tilbud fra producenter med følgende søgefiltre
      </Typography>

      {supplierMethods && <Box display='flex' mt={3} justifyContent='center' flexWrap={'wrap'} mb={1}>
        {renderChosenFilters(requirements.methods, supplierMethods.map(m => { return { id: m.methodId, name: m.method.name } }))}

        {renderChosenFilters(requirements.materials, supplierMaterials.map(m => { return { id: m.materialId, name: m.material.name } }))}

        {renderChosenFilters(requirements.certifications, supplierCertifications.map(m => { return { id: m.certificationId, name: m.certification.name.split(' (')[0] } }))}
      </Box>}

      {!supplierMethods && renderOrderFilters()}

    </Box>
  )
}

export default ReqSummary

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
});