import React, { useState, useEffect } from 'react'
import { Link, useLocation, useHistory } from "react-router-dom"

import makeStyles from '@mui/styles/makeStyles';
import {
  Box,
  Button,
  Typography,
  IconButton,
  Link as HrefLink
} from '@mui/material'

import AddCircleIcon from '@mui/icons-material/AddCircle';
import logo from '../assets/images/logo.svg'
import noTextLogo from '../assets/images/notextlogo.png'
import CityIcon from '../assets/images/city.png'
import OrderIcon from '../assets/images/clipboard.png'

import { LOGIN_URL } from '../api'

const menuItems = [
  {
    name: 'Ordrer',
    path: '/orders',
    icon: <img src={OrderIcon} alt='' />
  },
  {
    name: 'Indstillinger',
    path: '/settings',
    icon: <img src={CityIcon} alt='' />
  },
]

function Header() {
  const classes = useStyles()
  const location = useLocation()
  const [active, setActive] = useState({})
  const history = useHistory()

  useEffect(() => {
    if (location.pathname === '/') {
      setActive(menuItems[0])
    } else {
      setActive(menuItems.find(item => location.pathname === item.path) || {})
    }
  }, [location])

  return (
    <Box className={classes.header}>

      <Box>
        <HrefLink href={LOGIN_URL}>
          <img src={logo} alt='logo' width='160px' className={classes.hide} />
          <img src={noTextLogo} alt='logo' width={'35px'} className={classes.show} />
        </HrefLink>

        <Box className={classes.divider} mt={1} mb={3} />

        <Button className={classes.hide} fullWidth variant='contained' color='primary' onClick={() => history.push('/neworder')}>
          + Indhent tilbud
        </Button>

        <IconButton className={classes.show} onClick={() => history.push('/neworder')} style={{ padding: '0' }}>
          <AddCircleIcon color='primary' fontSize='large' />
        </IconButton>

        <Box className={classes.menuItems}>
          {menuItems.map((item, i) => {
            return <Link to={item.path} key={item.name} className={classes.item}>

              <Box>
                {item.icon}
              </Box>

              <Typography
                color={item.name === active.name ? 'primary' : ''}
                style={{ marginLeft: '16px', textAlign: 'right', fontWeight: item.name === active.name ? 'bold' : 'normal' }}
                className={classes.hide}
              >
                {item.name}
              </Typography>

            </Link>
          })}
        </Box>
      </Box>

    </Box>
  )
}

export default Header

const useStyles = makeStyles({
  header: {
    height: '100%',
    width: '260px',
    padding: '15px 30px 35px',
    position: 'fixed',
    top: '0',
    left: '0',
    zIndex: '2',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: 'linear-gradient(#061B37, #123667 100%)',
    '@media (max-width: 1250px)': {
      width: '65px',
      padding: '10px 15px'
    }
  },
  menuItems: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
    '@media (max-width: 1250px)': {
      alignItems: 'center',
    }
  },
  divider: {
    width: '100%',
    height: '1px',
    background: 'white'
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '30px',
    fontSize: '20px',
    letterSpacing: '1px',
    cursor: 'pointer',
    color: 'white'
  },
  hide: {
    '@media (max-width: 1250px)': {
      display: 'none'
    }
  },
  show: {
    '@media (min-width: 1250px)': {
      display: 'none'
    }
  }
});