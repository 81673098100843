import { Box, Typography, Stack, Link } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { formatDate } from '../../helpers/utilities'
import { DeliveryMethods, OrderTypes } from '../../helpers/enums'
import * as ndaApi from '../../api/nda'

export default function CustomerInfo(props) {
  const classes = useStyles()
  const { name, address, city, postal, phone, invoiceMail, email,
    offerExpire, urgent, purchaseNumber, company, orderType, deliveryMethod, deliveryAddress } = props.values

  const renderInfo = (name, value) => {
    return <Box display='flex'>
      <Box width='160px'>
        <Typography style={{ fontWeight: 'bold' }}>
          {name}:
        </Typography>
      </Box>
      <Box width='50%'>
        <Typography>
          {value}
        </Typography>
      </Box>
    </Box>
  }

  const renderNda = () => {
    return <Box display='flex'>
      <Box width='160px'>
        <Typography style={{ fontWeight: 'bold' }}>Fortrolighedsaftale</Typography>
      </Box>
      <Box width='50%'>
        {props.nda ?
          <Link onClick={() => ndaApi.routeToNda(props.nda.fileId)} sx={{ cursor: 'pointer', color: '#7B61FF' }}>{props.nda.file.fileName}</Link>
          :
          <Typography>Ingen</Typography>
        }
      </Box>
    </Box>
  }

  return (
    <Stack className={classes.container} gap={0.5}>
      {renderInfo('Navn', name)}
      {renderInfo('Tlf.', phone)}
      {renderInfo('Email', email)}
      {renderInfo('Virksomhed', company)}
      {renderInfo('Faktureringsadresse', `${address}, ${postal} ${city}`)}
      {renderInfo('Email til faktura', invoiceMail)}

      <Box className={classes.divider} />

      <Typography variant='h5'>Leveringsoplysninger</Typography>
      {renderInfo('Levering', DeliveryMethods[deliveryMethod])}
      {deliveryMethod === 1 && renderInfo('Leveringsadresse', deliveryAddress ? `${deliveryAddress.street}, ${deliveryAddress.postal} ${deliveryAddress.city}` : `${address}, ${postal} ${city}`)}

      <Box className={classes.divider} />

      <Typography variant='h5'>Andre oplysninger</Typography>
      {renderInfo('Type', OrderTypes[orderType])}
      {renderNda()}
      {renderInfo('Tilbudsfrist', offerExpire ? formatDate(new Date(offerExpire), 'PP') : 'Ingen')}
      {renderInfo('Hasteordre', urgent ? 'Ja' : 'Nej')}
      {purchaseNumber && renderInfo('PO nummer', purchaseNumber)}
      {renderInfo('Supplybase-ID', props.orderId)}
    </Stack>
  )
}

const useStyles = makeStyles({
  container: {
    borderRadius: '5px',
    backgroundColor: 'white',
    width: '100%',
  },
  divider: {
    height: '2px',
    background: '#C7C7C7',
    margin: '16px 0 8px'
  }
});