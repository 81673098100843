import AuthorizeUser from "../components/authorizeUser"
import NewOrder from "../pages/newOrder"
import Orders from "../pages/orders"
import Settings from '../pages/settings'
import OrderPage from '../pages/OrderPage'
import UserPage from "../pages/UserPage"
import CompareOffers from "../pages/compareOffers"

export const routes = [
  {
    path: '/authorize/:accessToken',
    content: AuthorizeUser
  },
  {
    path: '/neworder',
    content: NewOrder
  },
  {
    path: '/settings',
    content: Settings
  },
  {
    path: '/orders',
    content: Orders
  },
  {
    path: '/offers/:orderId',
    content: CompareOffers
  },
  {
    path: '/order/:orderId/:offerId',
    content: OrderPage
  },
  {
    path: '/user',
    content: UserPage
  },
  {
    path: '/*',
    content: Orders
  }
]