import clsx from 'clsx'

import {
  Box
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import AddSuppliers from '../../components/newOrder/addSuppliers'
import OpenOrDirect from '../../components/newOrder/openOrDirect'
import ProductionRequirements from '../../components/newOrder/productionRequirements'
import SearchSuppliers from '../../components/newOrder/searchSuppliers'
import { Actions } from './actions'
import { containerStyles } from './styles'
import { useSnackbar } from 'notistack'

export default function StepTwo(props) {
  const _classes = containerStyles()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar();

  const { RFQType, setRFQType, supplierMethods, supplierMaterials, supplierCertifications, locations } = props

  const next = async () => {
    if (RFQType === 'open') {
      if (props.requirements.methods.length < 1) {
        enqueueSnackbar('Vælg minimum én produktionsmetode', { variant: 'error' })
        return
      }

      if (props.requirements.materials.length < 1) {
        enqueueSnackbar('Vælg minimum ét materiale', { variant: 'error' })
        return
      }
    }

    props.next()
  }

  return (
    <Box className={clsx(_classes.container, classes.container)}>

      {!RFQType &&
        <>
          <OpenOrDirect setRFQType={setRFQType} />

          <Actions back={props.back} />
        </>
      }

      {RFQType === 'open' &&
        <>
          <ProductionRequirements
            filters={props.requirements}
            setFilters={props.setRequirements}
            supplierMethods={supplierMethods}
            supplierMaterials={supplierMaterials}
            supplierCertifications={supplierCertifications}
          />

          <Actions next={next} back={() => setRFQType()} />
        </>
      }

      {RFQType === 'direct' &&
        <>
          <AddSuppliers
            suppliers={props.suppliers}
            setSuppliers={props.setSuppliers}
            scrollToCompanies={props.scrollToCompanies}
          />

          <Actions next={next} back={() => setRFQType()} disabled={props.suppliers.length === 0} />

          <SearchSuppliers
            suppliers={props.suppliers}
            setSuppliers={props.setSuppliers}
            supplierMethods={supplierMethods}
            supplierMaterials={supplierMaterials}
            supplierCertifications={supplierCertifications}
            locations={locations}
          />
        </>}
    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    maxWidth: '1150px',
    margin: '20px auto',
    padding: '0'
  }
});