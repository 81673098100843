import { useEffect, useState } from 'react'
import { Stack, Typography, TextField, IconButton, Box, List, ListItem } from '@mui/material'
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import SendIcon from '@mui/icons-material/Send';
import * as orderApi from '../../api/order'
import { formatDate } from '../../helpers/utilities'
import { useSnackbar } from 'notistack'

const senderTypes = {
  supplier: "producent",
  customer: "kunde"
}

export default function Messages(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [comments, setComments] = useState([])
  const [comment, setComment] = useState('')
  let isSending = false;

  useEffect(() => {
    if (!props.requestId) return
    orderApi.getComments(props.requestId)
      .then(response => {
        setComments(response.data)
      }).catch(error => {

      })
  }, [props.requestId])

  const onSendMessage = () => {
    if (!comment || !props.requestId || isSending) return
    isSending = true

    orderApi.sendComment(props.requestId, comment)
      .then(response => {
        setComment('')
        setComments([response.data, ...comments])
      }).catch(error => {
        enqueueSnackbar('Beskeden kunne ikke sendes.', { variant: 'error' })
      }).finally(() => {
        isSending = false
      })
  }

  const SendhButton = () => (
    <IconButton color='primary' onClick={onSendMessage}>
      <SendIcon />
    </IconButton>
  )

  const Comment = (props) => {
    return (
      <Stack sx={{ minWidth: '100%' }}>
        <Box sx={{ bgcolor: '#dd850033' }} p={1}>
          <Typography style={{ fontWeight: 'bold' }}>{props.comment.name} ({senderTypes[props.comment.senderType]}):</Typography>
          <Typography whiteSpace={'pre-wrap'}>
            {props.comment.comment}
          </Typography>
        </Box>
        <Typography color='#828282' fontSize={12} align='right'>{formatDate(new Date(props.comment.createdAt), 'PP')}</Typography>
      </Stack>
    )
  }

  return (
    <Stack gap={1} p={2}>
      <Stack gap={1} direction='row'>
        <ChatRoundedIcon pt={1} />
        <Typography variant='h5'>{props.title ? props.title : 'Beskeder'}</Typography>
      </Stack >

      <List p={1} sx={{ border: '1px solid #C7C7C7', borderRadius: '5px', height: '275px', overflowY: "scroll", display: 'flex', flexDirection: 'column-reverse' }}>
        {comments.map(comment => {
          return (
            <ListItem key={comment.id}>
              <Comment comment={comment} />
            </ListItem>
          )
        })}
      </List>

      <TextField multiline value={comment} onChange={e => setComment(e.target.value)} placeholder="Skriv her..." InputProps={{ endAdornment: <SendhButton /> }} />
    </Stack >
  )
}