import React from 'react'

import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Typography
} from '@mui/material'

import { pageStyles } from './styles'
import CompanySettings from '../components/settings/companySettings'
import Employees from '../components/settings/employees'

export default function Settings() {
  const classes = useStyles()
  const _classes = pageStyles()

  return (
    <Box className={classes.container}>
      <Box className={_classes.pageTitle}>
        <Typography variant='h3'>Indstillinger</Typography>
      </Box>

      <Box p={3} width='100%' display='flex'>
        <CompanySettings />

        <Employees />
      </Box>

    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    paddingTop: '56px',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }
});