import React from 'react'
import clsx from 'clsx'

import makeStyles from '@mui/styles/makeStyles';
import { Typography, FormControl, RadioGroup, FormControlLabel, Radio, Stack } from '@mui/material'

import { DeliveryMethods } from '../../helpers/enums'
import { componentStyles } from '../styles'

export default function ShippingOptions(props) {
  const classes = useStyles()
  const _classes = componentStyles()

  return (
    <Stack gap={1} className={clsx(_classes.container, classes.container)}>
      <Typography variant='h5' required>
        Leveringsmuligheder
      </Typography>
      <FormControl component="fieldset" error={props.showError && props.values.deliveryMethod === -1}>
        <RadioGroup name={"deliveryMethod"} value={Number(props.values.deliveryMethod)} onChange={props.handleChange}>
          {Object.entries(DeliveryMethods).map(method =>
            <FormControlLabel key={method[0]} value={Number(method[0])} control={<Radio />} label={`${method[1]}`} />
          )}
        </RadioGroup>
      </FormControl>
    </Stack >
  )
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    marginBottom: '15px'
  },
});