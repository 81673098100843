import {
  Box,
  Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { useParams } from "react-router-dom"
import Order from '../containers/orders/order'
import { pageStyles } from './styles'

export default function OrderPage() {
  const classes = useStyles()
  const _classes = pageStyles()

  let { orderId, offerId } = useParams();

  return (
    <Box className={classes.container}>
      <Box className={_classes.pageTitle}>
        <Typography variant='h3'>{`Ordrenummer: ${orderId}`}</Typography>
      </Box>



      <Box width='100%' p={3} style={{ overflowY: 'scroll' }}>

        <Box>
          <Order id={orderId} offerId={offerId} />
        </Box>

      </Box>

    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    paddingTop: '56px',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }
});