import CancelIcon from '@mui/icons-material/Cancel';
import {
  Box,
  CircularProgress, Pagination, Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useRef, useState } from 'react';

import * as supplierApi from '../../api/supplier';
import FilterGroup from '../findSupplier/filterGroup';
import Overview from '../findSupplier/overview';
import { SearchBar } from '../findSupplier/searchBar';

const initialFilters = {
  search: "",
  page: 0,
  orderBy: null,
  direction: 'ASC',
  methods: [],
  materials: [],
  certifications: [],
  locations: []
}

function SearchSuppliers(props) {
  const classes = useStyles()

  const [total, setTotal] = useState(0)
  const [companies, setCompanies] = useState([])
  const [filters, setFilters] = useState(initialFilters)
  const [loading, setLoading] = useState(true)
  const scrollRef = useRef(null)

  const { supplierMethods, supplierMaterials, supplierCertifications, locations } = props

  useEffect(() => {
    async function searchCompanies() {
      setLoading(true)
      const data = await supplierApi.search(filters)
      setCompanies(data.rows)
      setTotal(data.count)
      setLoading(false)
    }

    searchCompanies()
  }, [filters])

  const executeScroll = () => scrollRef.current.scrollIntoView()

  const handleChange = (name, value) => {
    setFilters({ ...filters, [name]: value, page: 0 })
  }

  const handlePageChange = (event, value) => {
    if (filters.page + 1 === value) return
    executeScroll()
    setFilters({ ...filters, page: value - 1 })
  }

  const orderBy = (name) => {
    if (filters.orderBy === name) {
      if (filters.direction === 'ASC') {
        setFilters({ ...filters, direction: 'DESC' })
      } else {
        setFilters({ ...filters, orderBy: null, direction: 'ASC' })
      }
    } else {
      setFilters({ ...filters, orderBy: name, direction: 'ASC' })
    }
  }

  const addServiceFilter = (name, id, event) => {
    if (event.target?.checked) {
      setFilters({ ...filters, [name]: [...filters[name], id], page: 0 })
    } else {
      setFilters({ ...filters, [name]: filters[name].filter(f => f !== id), page: 0 })
    }
  }

  const renderSortButton = (text, name) => {
    return <Box
      className={classes.sortButton}
      ml={2}
      onClick={() => orderBy(name)}
      style={filters.orderBy === name ? { border: '3px solid #DD8500', padding: '1px 8px' } : {}}
    >
      <Typography variant='h6'>
        {text}
      </Typography>
    </Box>
  }

  const renderChosenFilters = (filters, all, type) => {
    return React.Children.toArray(
      filters.map(filter => <Box display='flex' alignItems='center' ml={2}>
        <Box mr={1}>
          <Typography>
            {all.find(a => a.id === filter)?.name}
          </Typography>
        </Box>
        <Box display='flex' onClick={() => addServiceFilter(type, filter, {})}>
          <CancelIcon color='red' fontSize='small' />
        </Box>
      </Box>)
    )
  }

  const pageCount = Math.ceil(total / 10)
  return (
    <Box className={classes.container} ref={scrollRef}>

      <Box className={classes.content} mt={5}>

        <Box width={'30%'} maxWidth={'375px'} mb={8} p={1} mr={1} style={{ backgroundColor: 'white', height: 'fit-content' }}>
          <FilterGroup
            search
            currentFilters={filters.methods}
            addFilter={(id, event) => addServiceFilter('methods', id, event)}
            name={"Produktionsmetode"}
            data={supplierMethods.map(m => { return { id: m.methodId, count: m.supplierCount, name: m.method.name, category: m.method.category } })}
          />

          <FilterGroup
            currentFilters={filters.materials}
            addFilter={(id, event) => addServiceFilter('materials', id, event)}
            name={"Materiale"}
            data={supplierMaterials.map(m => { return { id: m.materialId, count: m.supplierCount, name: m.material.name, category: m.material.type } })}
          />

          <FilterGroup
            currentFilters={filters.certifications}
            addFilter={(id, event) => addServiceFilter('certifications', id, event)}
            name={"Certificering"}
            data={supplierCertifications.map(m => { return { id: m.certificationId, count: m.supplierCount, name: m.certification.name, category: m.certification.category } })}
          />

          <FilterGroup
            search
            currentFilters={filters.locations}
            addFilter={(id, event) => addServiceFilter('locations', id, event)}
            name={"Geografisk placering"}
            data={locations.map(m => { return { id: m.postal, name: m.city, count: m.cityCount } })}
          />
        </Box>

        <Box width={'70%'} display='flex' flexDirection='column' alignItems='center' mb={8}>

          <SearchBar search={handleChange} />

          {(filters.methods.length > 0 || filters.materials.length > 0 || filters.certifications.length > 0 || filters.locations > 0) &&
            <Box display='flex' alignItems='center' width='100%' mb={2} flexWrap='wrap' maxWidth='735px'>

              <Typography variant='h6'>Valgte filtre:</Typography>

              {renderChosenFilters(filters.methods, supplierMethods.map(m => { return { id: m.methodId, name: m.method.name } }), 'methods')}

              {renderChosenFilters(filters.materials, supplierMaterials.map(m => { return { id: m.materialId, name: m.material.name } }), 'materials')}

              {renderChosenFilters(filters.certifications, supplierCertifications.map(m => { return { id: m.certificationId, name: m.certification.name.split(' (')[0] } }), 'certifications')}

              {renderChosenFilters(filters.locations, locations.map(m => { return { id: m.postal, name: m.city } }), 'locations')}

              <Box className={classes.removeFilters} ml={2} onClick={() => setFilters(initialFilters)}>
                <Typography variant='h6'>
                  Fjern filtre
                </Typography>
              </Box>

            </Box>}

          <Box display='flex' alignItems='center' width='100%' mb={2} maxWidth='735px'>
            <Typography variant='h6'>Sorter efter:</Typography>
            {renderSortButton('Navn', 'name')}
            {renderSortButton('Lokation', 'city')}
          </Box>

          {loading && <Box mt={5}><CircularProgress /></Box>}

          {!loading && <React.Fragment>
            {React.Children.toArray(
              companies.map(company =>
                <Overview company={company} setSuppliers={props.setSuppliers} suppliers={props.suppliers} />
              )
            )}
            <Pagination count={pageCount} variant="text" color="primary" shape='rounded' page={filters.page + 1} onChange={handlePageChange} />
          </React.Fragment>}

        </Box>

      </Box>
      
    </Box >
  )
}

export default SearchSuppliers

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto'
  },
  filterButtons: {
    marginTop: '20px',
    width: '830px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  pageNumber: {
    width: '32px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    borderRadius: '5px',
    cursor: 'pointer'
  },
  backToSearch: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    alignSelf: 'flex-start'
  },
  sortButton: {
    padding: '3px 10px',
    background: 'white',
    border: '1px solid #C7C7C7',
    boxSizing: 'border-box',
    borderRadius: '5px',
    cursor: 'pointer'
  },
  removeFilters: {
    color: '#EB5757',
    textDecorationLine: 'underline',
    cursor: 'pointer'
  }
});