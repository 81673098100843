import axios from 'axios'
import { API_ENDPOINT } from '.'

const API_ROUTE = `${API_ENDPOINT}/data/certification`

export async function getSupplierCertifications() {
  const url = `${API_ROUTE}/supplierCertifications`
  const response = await axios.get(url)
    .catch(err => err.response)

  return response ? response.data : null
}