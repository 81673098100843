import React, { useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import makeStyles from '@mui/styles/makeStyles';
import { Typography, FormControl, RadioGroup, FormControlLabel, Radio, Stack, Button, Box, Link } from '@mui/material'
import { componentStyles } from '../styles'
import { useDropzone } from 'react-dropzone'
import * as ndaAPI from '../../api/nda'
import { saveAs } from 'file-saver'

export default function Nda(props) {
  const classes = useStyles()
  const _classes = componentStyles()

  const [standardNda, setStandardNda] = useState()

  useEffect(() => {
    ndaAPI.getStandardNda()
      .then(response => {
        setStandardNda(response.data)
      })
  }, [])

  const onOpenStandardNda = () => {
    if (!standardNda) return
    ndaAPI.routeToStandardNda()
  }

  const onOpenUploadedNda = () => {
    saveAs(new Blob([props.values.ndaFile]), props.values.ndaFile.name)
  }

  const onDrop = useCallback(acceptedFile => {
    props.handleNDAChange(acceptedFile[0])
  }, [props])

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: '.pdf' })

  return (
    <Stack gap={1} className={clsx(_classes.container, classes.container)}>
      <Typography variant='h5' required>
        Fortrolighedsaftale (NDA)
      </Typography>
      <Typography>Vedhæfter du en fortrolighedsaftale skal alle tilbudsgivende producenter acceptere aftalen inden de kan tilgå de vedhæftede filer og tegninger. Du kan benytte {<Link sx={{ cursor: 'pointer', color: '#7B61FF' }} onClick={onOpenStandardNda}>Supplybase standard NDA</Link>} hvis I ikke har udarbejdet jeres egen. </Typography>

      <FormControl component="fieldset">
        <RadioGroup name="ndaType" value={props.values.ndaType} onChange={props.handleChange}>
          <FormControlLabel value='none' control={<Radio />} label='Benyt ikke NDA' />
          <FormControlLabel value='standard' control={<Radio />} label='Standard NDA' />
          <FormControlLabel value='uploaded' control={<Radio />} label='Upload NDA' />
        </RadioGroup>
      </FormControl>

      {props.values.ndaType === 'standard' &&
        <Typography>Se dokumentet her: {<Link sx={{ cursor: 'pointer', color: '#7B61FF' }} onClick={onOpenStandardNda}>{standardNda ? standardNda.file.fileName : 'link'}</Link>}</Typography>
      }

      {(props.values.ndaType === 'uploaded' && !props.values.ndaFile) &&
        <div {...getRootProps()} style={{ display: 'flex', outline: 'none', justifyContent: 'center' }}>
          <input {...getInputProps()} />
          {
            <Button variant='contained'>Upload fil</Button>
          }
        </div>
      }

      {(props.values.ndaType === 'uploaded' && props.values.ndaFile) &&
        <React.Fragment>
          <Typography>Se dokumentet her: {<Link onClick={onOpenUploadedNda} sx={{ cursor: 'pointer', color: '#7B61FF' }}>{props.values.ndaFile.name}</Link>}</Typography>
          <Box display='flex' sx={{ justifyContent: 'center' }}>
            <Button onClick={() => props.handleNDAChange(null)} color='error' variant='contained'>Fjern fil</Button>
          </Box>
        </React.Fragment>
      }
    </Stack >
  )
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    marginBottom: '15px'
  },
});