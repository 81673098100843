import { differenceInCalendarDays, format } from 'date-fns'
import daLocale from 'date-fns/locale/da'

export function fileIdentifier(file) {
  if (file.includes(".")) {
    file = file.slice(0, file.lastIndexOf("."))
  }

  const parts = file.split(/[._]+/)
  if (parts.length > 1 && /\d/.test(parts[parts.length - 1])) {
    return parts.slice(0, parts.length - 1).join("_")
  }

  return file
}

export function getRevision(fileName) {
  let cleanSlate = fileName
  if (cleanSlate.includes(".")) {
    cleanSlate = cleanSlate.slice(0, cleanSlate.lastIndexOf("."))
  }

  let parts = cleanSlate.split(/[._]+/)
  if (parts.length > 1 && /\d/.test(parts[parts.length - 1])) {
    return parts[parts.length - 1]
  }
  return fileName
}

export function calculateOfferDiffs(offer, offers) {
  const bestPrice = Math.min(...offers.map(offer => offer.total))
  const bestDelivery = offers.map(offer => offer.deliveryDate).sort()[0]

  let priceDiff
  if (offer.total > bestPrice) {
    priceDiff = Math.round((offer.total - bestPrice) / bestPrice * 100)
  }

  let dateDiff = differenceInCalendarDays(new Date(offer.deliveryDate), new Date(bestDelivery))

  return {
    priceDiff,
    dateDiff
  }
}

export function formatDate(date1, f, locale) {
  return format(date1, f, { locale: locale || daLocale })
}

export function dayDifference(date1, date2) {
  return differenceInCalendarDays(date1, date2)
}

export function companyNameToURLString(companyName) {
	return encodeURIComponent(companyName.replaceAll(' ', '_'));
}
