import React from 'react'
import { Dialog, Button, Typography, Stack, Box } from '@mui/material'

export default function UploadSameFilesDialog(props) {

  return (
    <Dialog open={props.open}  >
      <Stack gap={4} sx={container} textAlign='center'>
        <Typography variant='h4'>
          Fejl
        </Typography>

        <Typography>
          Vælger du typen "Enkelt emne" kan du kun uploade én fil per filtype af formatet .dxf, .step og .iges.
        </Typography>

        <Box>
          <Typography fontWeight={'bold'}>
            Er dit emne af konstruktion bestående af flere emner?
          </Typography>
          <Typography>
            Vælg da typen "Konstruktion"
          </Typography>
        </Box>

        <Button variant='contained' onClick={() => props.onClose()}>
          Okay
        </Button>
      </Stack>
    </Dialog>
  )
}

const container = {
  background: 'radial-gradient(#102D53 0%, #061B37 100%)',
  boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.25)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  color: 'white'
}