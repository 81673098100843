import React from 'react'

import { Box, Typography, Radio, Stack, RadioGroup, FormControlLabel } from '@mui/material'
import { formatDate } from '../../helpers/utilities'
import { DeliveryMethods, OrderTypes } from '../../helpers/enums'

function OrderDetails(props) {
  const { name, address, city, postal, phone, email, deliveryMethod,
    invoiceMail, offerExpire, urgent, orderName, orderType, company, deliveryAddress, deliverySameAs, deliveryStreet, deliveryCity, deliveryPostal } = props.values
  const renderInfo = (name, value) => {
    return <Box>
      <Typography style={{ fontWeight: 'bold' }}>
        {name}:
      </Typography>
      <Typography>
        {value}
      </Typography>
    </Box>
  }

  return (
    <Box display='flex'>
      <Box sx={{ width: '50%' }} mr={1}>
        <Stack sx={containerStyle}>
          <Typography variant='h5'>Kontaktoplysninger</Typography>
          {renderInfo('Fulde Navn', name)}
          {renderInfo('Telefonnr.', phone)}
          {renderInfo('Email', email)}

          <Typography mt={1} variant='h5'>Faktureringsaddresse</Typography>
          {renderInfo('Virksomhed', company)}
          {renderInfo('Email til faktura', invoiceMail)}
          {renderInfo('Adresse', address)}
          <Box display='flex'>
            <Box width={'50%'}>
              {renderInfo('Postnr.', postal)}
            </Box>
            {renderInfo('By', city)}
          </Box>
        </Stack >

        <Stack sx={containerStyle}>
          <Typography variant='h5'>Type af henvendelse</Typography>
          <RadioGroup value="true">
            <FormControlLabel value={"true"} control={<Radio />} label={OrderTypes[orderType]} />
          </RadioGroup>
        </Stack>
      </Box>

      <Box sx={{ width: '50%' }} ml={1}>
        <Stack sx={containerStyle}>
          <Typography variant='h5'>
            Leveringsmuligheder
          </Typography>
          <RadioGroup value="true">
            <FormControlLabel value={"true"} control={<Radio />} label={DeliveryMethods[deliveryMethod]} />
          </RadioGroup>
        </Stack>

        {(deliveryMethod === "1" || deliveryMethod === 1) &&
          <Stack sx={containerStyle}>
            <Typography variant='h5'>
              Leveringsaddresse
            </Typography>
            <RadioGroup value="true">
              <FormControlLabel value={"true"} control={<Radio />} label={(deliveryAddress || deliverySameAs === "false") ? "Anden leveringsaddresse" : "Samme som faktureringsaddresse"} />
            </RadioGroup>
            {renderInfo('Adresse', (deliveryAddress || deliverySameAs === "false") ? deliveryAddress ? deliveryAddress.street : deliveryStreet : address)}
            <Box display='flex'>
              <Box width={'50%'}>
                {renderInfo('Postnr.', (deliveryAddress || deliverySameAs === "false") ? deliveryAddress ? deliveryAddress.postal : deliveryPostal : postal)}
              </Box>
              {renderInfo('By', (deliveryAddress || deliverySameAs === "false") ? deliveryAddress ? deliveryAddress.city : deliveryCity : city)}
            </Box>
          </Stack>
        }

        <Stack sx={containerStyle}>
          <Typography variant='h5'>
            Andre oplysninger
          </Typography>
          {renderInfo('Navn på ordre', orderName ? orderName : "Ingen")}
          {renderInfo('Tilbudsfrist', offerExpire ? formatDate(new Date(offerExpire), 'PP') : 'Ingen')}
          {renderInfo('Hasteordre', urgent ? 'Ja' : 'Nej')}
        </Stack>
      </Box>
    </Box>
  )
}

export default OrderDetails

const containerStyle = {
  boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.25)',
  borderRadius: '5px',
  backgroundColor: 'white',
  padding: '20px',
  mb: 2,
  gap: 1
}

