import {
  Box,
  Tab,
  Tabs,
  Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useState } from 'react'
import EmailSettings from '../components/user/emailSettings'
import UserInfo from '../components/user/userInfo'
import { pageStyles } from './styles'

export default function UserPage() {
  const classes = useStyles()
  const _classes = pageStyles()

  const [value, setValue] = useState(0);

  return (
    <Box className={classes.container}>
      <Box className={_classes.pageTitle}>
        <Typography variant='h3'>Min profil</Typography>
      </Box>

      <Box width='100%' p={3} style={{ overflowY: 'scroll' }}>

        <Tabs value={value} onChange={(e, value) => setValue(value)} textColor="#000" indicatorColor="primary">
          <Tab label={'Indstillinger'} value={0} />
        </Tabs>

        <Box mt={3} display='flex'>
          <UserInfo />

          <EmailSettings />
        </Box>

      </Box>

    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    paddingTop: '56px',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }
});