import React from 'react'
import clsx from 'clsx'
import makeStyles from '@mui/styles/makeStyles'
import { Typography, FormControlLabel, Stack, Box, TextField, RadioGroup, Radio, FormControl } from '@mui/material'
import { componentStyles } from '../styles'

export default function DeliveryAddress(props) {
  const classes = useStyles()
  const _classes = componentStyles()

  return (
    <Stack gap={2} className={clsx(_classes.container, classes.container)}>
      <Typography variant='h5' required>
        Leveringsaddresse
      </Typography>
      <FormControl component="fieldset">
        <RadioGroup name={"deliverySameAs"} value={props.values.deliverySameAs} onChange={props.handleChange}>
          <FormControlLabel value={"true"} control={<Radio />} label="Samme som faktureringsaddresse" />
          <FormControlLabel value={"false"} control={<Radio />} label="Anden leveringsaddresse" />
        </RadioGroup>
      </FormControl>

      {props.values.deliverySameAs === "false" &&
        <React.Fragment>
          <TextField name={'deliveryStreet'} label="Adresse" variant="outlined" fullWidth required value={props.values.deliveryStreet} onChange={props.handleChange} error={!props.values.deliveryStreet && props.showError} />
          <Box display='flex' >
            <TextField name={'deliveryPostal'} sx={{ mr: 2 }} label="Postnr." variant="outlined" required value={props.values.deliveryPostal} onChange={props.handleChange} error={!props.values.deliveryPostal && props.showError} />
            <TextField name={'deliveryCity'} label="By" variant="outlined" fullWidth required value={props.values.deliveryCity} onChange={props.handleChange} error={!props.values.deliveryCity && props.showError} />
          </Box>
        </React.Fragment>}
    </Stack >
  )
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    marginBottom: '15px'
  },
});