import axios from 'axios'
import { CUSTOMER_ENDPOINT, buildApiHeaders } from '.'

const API_ROUTE = `${CUSTOMER_ENDPOINT}/order`

export async function getRequest(id) {
  const url = `${API_ROUTE}/request`
  return axios.get(url, {
    params: {
      requestId: id
    },
    headers: buildApiHeaders()
  })
}

export async function getRequests(orderId) {
  const url = `${API_ROUTE}/requests`
  return axios.get(url, {
    params: {
      orderId
    },
    headers: buildApiHeaders()
  })
}

export async function getOrder(orderId, offerId) {
  const url = `${API_ROUTE}/`
  const response = await axios.get(url, {
    params: {
      orderId,
      offerId
    },
    headers: buildApiHeaders()
  }).catch(err => err.response)

  return response ? response.data : null
}

export async function createOrder(order, suppliers, orderItems, requirements, rfqType) {
  const url = `${API_ROUTE}/new`

  const response = await axios.post(url, {
    order,
    suppliers,
    orderItems,
    requirements,
    rfqType
  }, { headers: buildApiHeaders() })
    .catch(err => err.response)

  return response ? response.data : null
}

export async function guestOrder(order, suppliers, orderItems, requirements, rfqType) {
  const url = `${API_ROUTE}/guestOrder`

  const response = await axios.post(url, {
    order,
    suppliers,
    orderItems,
    requirements,
    rfqType
  }, { headers: buildApiHeaders() })
    .catch(err => err.response)

  return response ? response.data : null
}

export async function getOpenOrder(orderId) {
  const url = `${API_ROUTE}/open`

  const response = await axios.get(url, {
    params: {
      orderId
    },
    headers: buildApiHeaders()
  }).catch(err => err.response)

  return response ? response.data : null
}

export async function editOpenOrder(orderId, newOrderItems, newComment, newOfferDeadline) {
  const url = `${API_ROUTE}/edit`

  const response = await axios.post(url, {
    orderId,
    newOrderItems,
    newComment,
    newOfferDeadline
  }, { headers: buildApiHeaders() })
    .catch(err => err.response)

  return response ? response.data : null
}

export async function getOrders(status) {
  const url = `${API_ROUTE}/list`
  const response = await axios.get(url, {
    params: {
      status
    },
    headers: buildApiHeaders()
  }).catch(err => err.response)

  return response ? response.data : null
}

export async function getPreviousOrders() {
  const url = `${API_ROUTE}/previousList`
  const response = await axios.get(url, {
    headers: buildApiHeaders()
  }).catch(err => err.response)

  return response ? response.data : null
}

export async function getOrderOverview(orderId) {
  const url = `${API_ROUTE}/overview`
  const response = await axios.get(url, {
    params: {
      orderId
    },
    headers: buildApiHeaders()
  }).catch(err => err.response)

  return response ? response.data : null
}

export async function addPurchaseNumber(purchaseNumber, orderId) {
  const url = `${API_ROUTE}/purchaseNumber`

  const response = await axios.post(url, {
    purchaseNumber,
    orderId
  }, { headers: buildApiHeaders() })
    .catch(err => err.response)

  return response ? response.data : null
}

export async function getComments(requestId) {
  const url = `${API_ROUTE}/comments`
  return axios.get(url, {
    params: {
      orderRequestId: requestId
    },
    headers: buildApiHeaders()
  })
}

export async function sendComment(requestId, comment) {
  const url = `${API_ROUTE}/comment`
  return axios.post(url, { orderRequestId: requestId, comment: comment }, { headers: buildApiHeaders() })
}

export async function feedback(orderId, rating, comment) {
  const url = `${API_ROUTE}/feedback`

  const response = await axios.post(url, {
    orderId,
    rating,
    comment
  }, { headers: buildApiHeaders() })
    .catch(err => err.response)

  return response ? response.data : null
}