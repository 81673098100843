import {
  Box,
  Button, Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import { componentStyles } from '../styles';
import SupplierOverview from '../supplierOverview';

function AddSuppliers(props) {
  const classes = useStyles()
  const _classes = componentStyles()

  const renderNoCompany = () => {
    return <Box className={classes.noCompany} ml={2}>
      <Box className={classes.center} onClick={props.scrollToCompanies}>
        <Typography variant='h2'>
          +
        </Typography>
      </Box>
    </Box>
  }

  const renderCompany = (company) => {
    const { id } = company

    return <Box mx={1}>

      <SupplierOverview company={company} small />

      <Box mt={1.5} display='flex' justifyContent='center'>
        <Button
          variant='contained'
          color='red'
          style={{ height: '32px' }}
          onClick={() => props.setSuppliers(props.suppliers.filter(s => s.id !== id))}
        >
          Fjern
        </Button>
      </Box>

    </Box>
  }

  return (
    <Box className={clsx(_classes.container, classes.container)} my={2}>

      <Box pr={2}>
        <Typography variant='h4'>
          Vælg op til 3 <br />producenter at<br /> modtage tilbud fra
        </Typography>
        <Box className={classes.divider} my={1} />
        <Typography variant='h6'>
          Er din producent ikke på Supplybase?
        </Typography>
        <Box my={1}>
          <Typography>
            Fortæl din producent om Supplybase og benyt dem når du indhenter tilbud. Ved spørgsmål angående oprettelse kan nye producenter kontakte os på kontakt@supplybase.dk. Sammen digitaliserer vi fremstillingsindustrien!
          </Typography>
        </Box>
      </Box>

      <Box display='flex'>
        {props.suppliers[0] ? renderCompany(props.suppliers[0]) : renderNoCompany()}
        {props.suppliers[1] ? renderCompany(props.suppliers[1]) : renderNoCompany()}
        {props.suppliers[2] ? renderCompany(props.suppliers[2]) : renderNoCompany()}
      </Box>
    </Box>
  )
}

export default AddSuppliers

const useStyles = makeStyles({
  container: {
    width: '100%',
    minHeight: '335px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  divider: {
    height: '6px',
    width: '50px',
    background: '#DD8500'
  },
  noCompany: {
    width: '190px',
    height: '280px',
    backgroundColor: '#C7C7C7',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  center: {
    width: '65px',
    height: '65px',
    background: '#ABABAB',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#C7C7C7',
    cursor: 'pointer'
  },
  logo: {
    maxHeight: '95%',
    maxWidth: '95%',
    margin: 'auto'
  },
});