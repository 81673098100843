import React, { useState } from 'react'
import { useHistory } from "react-router-dom"

import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Typography
} from '@mui/material'

import { Actions } from './actions'
import { containerStyles } from './styles'
import { FileTable } from '../../components/orders/fileTable'
import Companies from '../../components/newOrder/companies'
import OrderDetails from '../../components/newOrder/orderDetails'
import { OrderCreatedDialog } from '../../components/dialogs/orderCreatedDialog'

import ReqSummary from '../../components/newOrder/reqSummary'
import * as orderApi from '../../api/order'
import * as fileApi from '../../api/file'
import SendingOrderDialog from '../../components/dialogs/sendingOrder'
import CheckMailDialog from '../../components/dialogs/checkMailDialog'
import { useSnackbar } from 'notistack'

export default function StepFour(props) {
  const _classes = containerStyles()
  const classes = useStyles()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar();

  const { values, suppliers, orderItems, requirements, supplierMethods, supplierMaterials, supplierCertifications, RFQType, isGuest } = props
  const [orderNumber, setOrderNumber] = useState(null)
  const [isNextDisabled, setIsNextDisabled] = useState(false)
  const [sendingProgress, setSendingProgress] = useState(0)
  const [isSending, setIsSending] = useState(false)
  const [checkEmail, showCheckEmail] = useState(false)

  let progressRaw = 0;

  const fillProgress = (amount) => {
    progressRaw = progressRaw + amount
    setSendingProgress(progressRaw)
  }

  const next = async () => {
    if (isNextDisabled) {
      return
    }

    progressRaw = 0;

    setIsNextDisabled(true)

    const totalFiles = []
    const fileStores = []

    let fillAmount = 0

    orderItems.forEach(item => {
      item.files.forEach(file => {
        totalFiles.push(file);
      })
    })

    fillAmount = 100 / (totalFiles.length)

    totalFiles.forEach(file => {
      fileStores.push(uploadFile(file, fillAmount))
    })

    if (totalFiles.length < 1) {
      enqueueSnackbar("Der er ingen filer tilknyttet. Gå tilbage til trin 1.", { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' } })
      return
    }

    setIsSending(true)
    await Promise.all(fileStores)

    if (values.ndaType === 'uploaded') {
      const storageId = await fileApi.uploadFile(values.ndaFile)
      values.ndaFileId = storageId
    }

    if (isGuest) {
      const order = await orderApi.guestOrder(values, suppliers, orderItems, requirements, RFQType)
      if (order.message) {
        enqueueSnackbar(order.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'center' } })
      } else {
        showCheckEmail(true)
      }
    } else {
      const order = await orderApi.createOrder(values, suppliers, orderItems, requirements, RFQType)

      if (order) {
        setOrderNumber(order.id)
      }
    }

    setIsSending(false)
    setIsNextDisabled(false)
  }

  const uploadFile = async (file, fillAmount) => {
    const storageId = await fileApi.addFile(file)
    file.storageId = storageId
    fillProgress(fillAmount)
  }

  return (
    <Box className={_classes.container}>

      <Box className={classes.container} p={2}>

        <Typography variant='h5'>
          Din ordre består af følgende
        </Typography>

        <Box className={classes.container} mt={2}>
          <FileTable orderItems={props.orderItems} />
        </Box>

        <Box display='flex' justifyContent='flex-end' width='100%' my={3} pr={3}>
          <Typography variant='h5'>
            Ordrestørrelse: {props.orderItems.reduce((a, b) => Number(a) + Number(b.quantity), 0)} stk.
          </Typography>
        </Box>

        {props.values.comment &&
          <React.Fragment>
            <Typography variant='h5'>
              Kommentar til ordre
            </Typography>

            <Box className={classes.commentField} mt={2} p={1}>
              <Typography>
                {props.values.comment}
              </Typography>
            </Box>
          </React.Fragment>}

      </Box>

      {RFQType === 'direct' && <Box>
        <Companies suppliers={suppliers} edit={true} />
      </Box>}

      {RFQType === 'open' && <Box>
        <ReqSummary
          requirements={requirements}
          supplierMethods={supplierMethods}
          supplierMaterials={supplierMaterials}
          supplierCertifications={supplierCertifications}
        />
      </Box>}

      <Box>
        <OrderDetails values={props.values} />
      </Box>

      <Actions next={next} back={props.back} finished />
      <SendingOrderDialog open={isSending} progress={sendingProgress} />
      <OrderCreatedDialog open={Boolean(orderNumber)} close={() => history.push('/orders')} orderNumber={orderNumber} />
      <CheckMailDialog open={checkEmail} email={values.email} />
    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    backgroundColor: 'white'
  },
  commentField: {
    border: '1px solid #C7C7C7',
    borderRadius: '5px',
    minHeight: '100px'
  }
});