import React, { useEffect } from 'react'
import { Redirect, useParams } from "react-router-dom"
import { useQuery } from '../helpers/hooks';

export default function AuthorizeUser() {
  let { accessToken } = useParams();
  const query = useQuery()

  useEffect(() => {
    if (accessToken) {
      localStorage.setItem('jwtToken', accessToken)
    }
  }, [accessToken])

  if (query.get("orderId")) {
    return <Redirect to={`/order/${query.get("orderId")}/${query.get("offerId")}`} />
  }

  if (query.get("offerId")) {
    return <Redirect to={`/offers/${query.get("offerId")}`} />
  }

  return (
    <Redirect to="/orders" />
  )
}