import axios from 'axios';
import { API_ENDPOINT } from '.';

const API_ROUTE = `${API_ENDPOINT}/public`;

/**
 * @param {string} params.name
 * @param {string} params.email
 */
export async function signupNewsletter(params) {
  const url = `${API_ROUTE}/newsletter`;

  await axios.post(url, params).then(function (response) {
    return response;
  }).catch(function (error) {
    throw error;
  });
}

/**
 * @param {string} params.email the email to be unsubscribed
 */
export async function unsubscribeNewsletter(params) {
  const url = `${API_ROUTE}/newsletter`;
  return await axios.delete(url, { data: params });
}

/**
 * @param {string} params.email the email to be fetched
 */
export async function getNewsletterContactInfo(params) {
  const url = `${API_ROUTE}/newsletter`;
  return await axios.get(url, { params: params });
}