import axios from 'axios'
import { API_ENDPOINT } from '.'

const API_ROUTE = `${API_ENDPOINT}/supplier/company`

export async function search(filters) {
  const url = `${API_ROUTE}/search`
  const response = await axios.post(url, { filters })
    .catch(err => err.response)

  return response ? response.data : null
}

export async function getLocations() {
  const url = `${API_ROUTE}/locations`
  const response = await axios.get(url)
    .catch(err => err.response)

  return response ? response.data : null
}

export async function routeToTradeTerms(supplierId) {
  const url = `${API_ROUTE}/terms`

  const response = await axios.get(url, { params: { supplierId } })
  if (!response) throw new Error("Ingen handelsbetingelser")
  const terms = response.data
  if (terms.link) {
    let link = terms.link
    if (!link.includes("https://")) link = `https://${terms.link}`
    window.open(link, '_blank')
  } else if (terms.attachment) {
    window.open(`${API_ENDPOINT}/file/image/${terms.attachment}`, "_blank")
  } else {
    throw new Error("No terms found")
  }

}