import React, { useState } from 'react'
import clsx from 'clsx'

import makeStyles from '@mui/styles/makeStyles'
import {
  Dialog,
  Box,
  Button,
  Typography,
  TextField
} from '@mui/material'

import { dialogStyles } from './styles'

export default function AddPurchaseNumberDialog(props) {
  const _classes = dialogStyles()
  const classes = useStyles()

  const [number, setNumber] = useState('')

  return (
    <Dialog open={props.open} onClose={props.close} maxWidth='sm'>
      <Box className={clsx(_classes.dialog, classes.dialog)}>

        <Box mb={3}>
          <Typography variant='h4'>
            Angiv købsordrenummer
          </Typography>
        </Box>

        <Box mb={3}>
          <Typography>
            Skal ordren tilknyttes et PO-nummer? Skriv det herunder eller tryk fortsæt.
          </Typography>
        </Box>

        <Box mb={4} width='100%'>
          <TextField
            className={_classes.input}
            label="PO-nummer (Valgfri)"
            variant="filled"
            fullWidth
            value={number}
            onChange={(e) => setNumber(e.target.value)}
          />
        </Box>

        <Box display='flex' justifyContent='center' width='100%'>
          <Button variant='contained' onClick={() => props.continue(number)}>
            Fortsæt
          </Button>
        </Box>

      </Box>
    </Dialog>
  )
}

const useStyles = makeStyles({
  dialog: {
    padding: '20px',
  },
})