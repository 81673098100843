import axios from 'axios'
import { CUSTOMER_ENDPOINT, buildApiHeaders } from '.'

const API_ROUTE = `${CUSTOMER_ENDPOINT}/company`

export async function getMe() {
  const url = `${API_ROUTE}/me`
  const response = await axios.get(url, { headers: buildApiHeaders() })
    .catch(err => err.response)

  return response ? response.data : null
}

export async function update(values) {
  const url = `${API_ROUTE}/update`
  const response = await axios.post(url, values, { headers: buildApiHeaders() })
    .catch(err => err.response)

  return response ? response.data : null
}