import axios from 'axios'
import { API_ENDPOINT } from '.'

const API_ROUTE = `${API_ENDPOINT}/nda`

export async function getStandardNda() {
  const url = `${API_ROUTE}`
  return axios.get(url)
}

export function routeToStandardNda() {
  getStandardNda()
    .then(response => {
      window.open(`${API_ENDPOINT}/file/image/${response.data.fileId}`, "_blank")
    })
}

export function routeToNda(fileId) {
  window.open(`${API_ENDPOINT}/file/image/${fileId}`, "_blank")

}