import React from 'react'
import clsx from 'clsx'

import makeStyles from '@mui/styles/makeStyles'
import { FormControlLabel, FormControl, RadioGroup, Radio, Typography, Stack } from '@mui/material'

import { componentStyles } from '../styles'
import { OrderTypes } from '../../helpers/enums'

export default function OrderType(props) {
  const classes = useStyles()
  const _classes = componentStyles()

  return (
    <Stack gap={1} className={clsx(_classes.container, classes.container)}>
      <Typography variant='h5' required>
        Type af henvendelse
      </Typography>
      <FormControl component="fieldset" error={props.showError && !props.values.orderType}>
        <RadioGroup name={"orderType"} value={props.values.orderType} onChange={props.handleChange}>
          {Object.entries(OrderTypes).map(type =>
            <FormControlLabel key={type[0]} value={type[0]} control={<Radio />} label={`${type[1]}`} />
          )}
        </RadioGroup>
      </FormControl>
    </Stack>
  )
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    marginBottom: '15px'
  },
});