import {
  Box, Link, Modal, Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';

import { LOGIN_URL } from '../../api';
import { OrderRequestProgress } from '../../helpers/enums';
import { formatDate, companyNameToURLString } from '../../helpers/utilities';
import Messages from '../orders/messages';
import Visibility from '@mui/icons-material/Visibility'
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import NumberFormat from 'react-number-format';


const renderProfileLink = (supplier) => {
  return <Link href={`${LOGIN_URL}/virksomheder/${companyNameToURLString(supplier.name)}`} target='_blank'>
    <Typography sx={{ color: '#242729', fontWeight: 'bold' }}>
      <Box display='flex' gap='5px'>
        {supplier.name}</Box>
    </Typography>
  </Link >
}

const renderShowButton = (supplier) => {
  return <Link href={`${LOGIN_URL}/virksomheder/${companyNameToURLString(supplier.name)}`} target='_blank'>
    <IconButton color='secondary' aria-label="Vis virksomhedsprofil">
      <Visibility fontSize="small" />
    </IconButton>
  </Link >
}


export default function SuppliersTable(props) {
  const classes = useStyles()
  const [messagesId, setMessagesId] = useState()

  const { orderRequests } = props

  const columns = [
    { field: 'supplier', headerName: 'Producent', minWidth: 300, renderCell: (x) => renderProfileLink(x.row.supplier), valueGetter: (x) => x.row.supplier.name },
    { field: 'show', headerName: 'Vis', minWidth: 50, renderCell: (x) => renderShowButton(x.row.supplier) },
    { field: 'messages', headerName: 'Beskeder', minWidth: 130, renderCell: (x) => renderMsgStatus(x.row.id, x.row.order_request_comments.length), valueGetter: (x) => x.row.order_request_comments.length },
    { field: 'location', headerName: 'Lokation', minWidth: 200, valueGetter: (x) => x.row.supplier.city },
    {
      field: 'price', headerName: 'Pris', minWidth: 120, renderCell: (x) => x.row.offer ? <NumberFormat
        value={Number(x.row.offer.total)}
        displayType={'text'}
        thousandSeparator={'.'}
        decimalSeparator={','}
        prefix={'DKK '}
        decimalScale={2}
      /> : <Box>--</Box>
    },
    { field: 'deliveryDate', headerName: 'Leveringsdato', minWidth: 140, valueGetter: (x) => x.row.offer?.deliveryDate ? formatDate(new Date(x.row.offer.deliveryDate), 'PP') : '--' },
    { field: 'status', headerName: 'Status', minWidth: 120, renderCell: (x) => renderRequestStatus(x.row, x.row.offer), valueGetter: (x) => x.row.offer ? '1' : x.row.seen ? '2' : x.row.status }
  ]


  const renderRequestStatus = (orderRequest, offer) => {
    let color = 'rgba(0, 0, 0, 0.08)'
    let text = 'Ikke åbnet'

    if (orderRequest.seen) {
      color = '#D2FF52'
      text = 'Åbnet'
    }

    if (orderRequest.offer) {
      color = '#18BC3C'
      text = 'Se tilbud'
    }

    if (!orderRequest.seen) {
      text = 'Ikke åbnet'
    }

    if (orderRequest.status === OrderRequestProgress.REJECTED) {
      color = '#EB5757'
      text = 'Afvist'
    }

    return <Box
      sx={{ backgroundColor: color, borderRadius: '16px', padding: '7px 10px', cursor: 'pointer' }}
      onClick={() => { if (offer) props.setOffer(offer.id) }}
    >
      {text}
    </Box>
  }

  const renderMsgStatus = (orderRequestId, messages) => {
    let text = <Button variant="contained" size="small">{`${messages} nye beskeder`}</Button>

    if (messages === 0) {
      text = <Button variant="outlined" size="small"> Send besked</Button>
    }

    if (messages === 1) {
      text = <Button variant="contained" size="small"> 1 ny besked</Button>
    }

    return <Box
      sx={{ cursor: 'pointer' }}
      onClick={() => setMessagesId(orderRequestId)}>


      <Typography>
        {text}
      </Typography>
    </Box>
  }

  const renderStatusInfo = (backgroundColor, text) => {
    return <Box display='flex' alignItems='center' mr={3} mt={2}>

      <Box className={classes.circle} style={{ backgroundColor }} mr={1} />
      <Typography>{text}</Typography>

    </Box>
  }

  return (
    <Box className={classes.container} ml={3}>

      <Typography variant='h5'>
        Underrettede producenter
      </Typography>

      <Box display='flex'>
        {renderStatusInfo('#D2FF52', 'Set af producent')}
        {renderStatusInfo('#EB5757', 'Afvist af producent')}
        {renderStatusInfo('#18BC3C', 'Tilbud modtaget')}
      </Box>

      <Box minWidth='100%' mt={2} sx={{ backgroundColor: 'white' }}>
        <DataGrid
          rows={orderRequests}
          columns={columns}
          autoHeight
          rowsPerPageOptions={[10]}
          disableColumnMenu
          disableSelectionOnClick
          onRowClick={(x) => { if (x.row.offer) props.setOffer(x.row.offer.id) }}
        />
      </Box>

      <Modal open={Boolean(messagesId)} onClose={() => setMessagesId()}>
        <Box className={classes.msgContainer} >
          <Messages requestId={messagesId} />
        </Box>
      </Modal>

    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%'
  },
  statusContainer: {
    borderRadius: '16px',
    padding: '7px 10px'
  },
  msgContainer: {
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    borderRadius: '5px',
    backgroundColor: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    minWidth: '550px'
  },
  circle: {
    width: '19px',
    height: '19px',
    borderRadius: '100%'
  },
});