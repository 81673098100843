import React from 'react'

import {
  Box,
  Button, IconButton,
  Input, Stack, Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { AddFileButton } from '../addFileButton'

const cells = [
  { id: 'name', label: 'Navn', align: 'left', sortable: true },
  { id: 'amount', label: 'Type', align: 'right', sortable: true },
  { id: 'value', label: 'Filer', align: 'right', sortable: true },
  { id: 'returns', label: 'Antal', align: 'right', sortable: true },
]

export function FileTable(props) {
  const classes = useStyles()

  const removeFile = (fileName, row) => {
    props.setOrderItems(prevState => {
      const orderItem = { ...prevState[row] }
      return Object.assign([], prevState, { [row]: { ...orderItem, files: orderItem.files.filter(f => f.name !== fileName) } })
    })
  }

  const handleAmountChange = (row, value) => {
    props.setOrderItems(prevState => {
      return Object.assign([], prevState, { [row]: { ...prevState[row], quantity: value } })
    })
  }

  return (
    <React.Fragment>
      <Table className={classes.fileTable} size='small'>

        <TableHead>
          <TableRow className={classes.tableHeader}>
            {cells.map(cell => (
              <TableCell key={cell.id} align={"center"} style={{ border: '2px solid white' }}>
                <Box color='white' display='flex' justifyContent='center' alignItems='center'>
                  <Typography variant='h6'>{cell.label}</Typography>
                  {(props.edit || props.editExisting) && <Box ml={0.5}>
                    <Typography variant='body1'>{cell.subLabel}</Typography>
                  </Box>}
                </Box>
              </TableCell>
            ))}

            {props.removeButton && <TableCell width={'100px'} style={{ border: '2px solid white' }} />}

          </TableRow>
        </TableHead>

        <TableBody>

          {props.orderItems.map((orderItem, rowIndex) => (
            <TableRow key={orderItem.identifier}>

              <TableCell>
                <Typography variant='h6' noWrap>
                  {orderItem.name}
                </Typography>
              </TableCell>

              <TableCell>
                <Typography variant='h6'>
                  {orderItem.type === 'single' ? 'Emne' : orderItem.type === 'construction' ? 'Konstruktion' : ''}
                </Typography>
              </TableCell>

              <TableCell>
                {props.editExisting && <AddFileButton uploadFile={(file) => props.uploadFile(file, orderItem)} />}

                <Stack direction={'row'} columnGap={2} rowGap={0.5} justifyContent={'center'} flexWrap={'wrap'}>
                  {React.Children.toArray(
                    orderItem.files.map(item => <Stack direction={'row'} alignItems={'center'} gap={0.5}>
                      <Typography>
                        {item.name}
                      </Typography>

                      {(props.edit || props.editExisting) && <IconButton onClick={() => removeFile(item.name, rowIndex)} sx={{ p: 0 }}>
                        <RemoveCircleIcon style={{ color: '#E73030' }} />
                      </IconButton>}
                    </Stack>)
                  )}
                </Stack>
              </TableCell>

              <TableCell>

                {!props.edit
                  ? <Box my={1}>
                    <Typography variant='h6'>
                      {orderItem.quantity}
                    </Typography>
                  </Box>

                  : <Input
                    value={orderItem.quantity}
                    type="number"
                    inputProps={{ className: classes.amountInput }}
                    className={classes.amountContainer}
                    onChange={(e) => handleAmountChange(rowIndex, e.target.value)}
                    onBlur={(e) => { if (!e.target.value || e.target.value < 0) handleAmountChange(rowIndex, 0) }}
                  />}

              </TableCell>

              {props.removeButton && <TableCell>
                <Button variant='contained' color='red' onClick={() => props.setOrderItems(props.orderItems.filter(item => item !== orderItem))}>
                  Fjern
                </Button>
              </TableCell>}

            </TableRow>
          ))}
        </TableBody>

      </Table>
    </React.Fragment>
  )
}

const useStyles = makeStyles({
  fileTable: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableHeader: {
    backgroundColor: '#061B37',
  },
  amountInput: {
    width: '45px',
    height: '40px',
    border: '2px solid #DD8500',
    textAlign: 'center',
    borderRadius: '5px',
    padding: 0
  },
  amountContainer: {
    border: 'none !important',
    '&::before': {
      border: 'none !important'
    },
    '&::after': {
      border: 'none !important'
    }
  }
})