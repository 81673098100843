import lodash from 'lodash'
import React, { useEffect, useState } from 'react'

import {
  Box, Button, Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { useHistory } from 'react-router-dom'
import * as fileApi from '../../api/file'
import * as orderApi from '../../api/order'
import ApproveEditDialog from '../../components/dialogs/approveEditDialog'
import CancelEditDialog from '../../components/dialogs/cancelEditDialog'
import Companies from '../../components/newOrder/companies'
import OrderDetails from '../../components/newOrder/orderDetails'
import ReqSummary from '../../components/newOrder/reqSummary'
import { FileTable } from '../../components/orders/fileTable'

export default function OpenOrder(props) {
  const classes = useStyles()
  const history = useHistory()

  const [order, setOrder] = useState(null)
  const [loading, setLoading] = useState(true)
  const [edit, setEdit] = useState(false)
  const [cancelEditDialog, showCancelEditDialog] = useState(false)
  const [approveEditDialog, showApproveEditDialog] = useState(false)
  const [isApproveDialogDisabled, setIsApproveDialogDisabled] = useState(false)

  const [newOrderItems, setNewOrderItems] = useState(null)
  const [newComment, setNewComment] = useState('')
  const [newOfferDeadline, setNewOfferDeadline] = useState()

  useEffect(() => {
    getOrder()
    // eslint-disable-next-line
  }, [props.orderId])

  async function getOrder() {
    setLoading(true)
    const data = await orderApi.getOpenOrder(props.orderId)

    if (data) {
      setOrder(data)
      setNewComment(data.comment)
      setNewOfferDeadline(data.offerExpire)
      setNewOrderItems(lodash.cloneDeep(data.orderItems))
    }
    setLoading(false)
  }

  if (loading) {
    return <Box />
  }

  const storeFile = async (file, orderItem) => {
    setNewOrderItems(prevState => {
      const rowIndex = prevState.findIndex(item => item === orderItem)
      orderItem.files.push({ name: file.name })
      return Object.assign([], prevState, { [rowIndex]: orderItem })
    })

    const storageId = await fileApi.addFile(file)
    setNewOrderItems(prevState => {
      const rowIndex = prevState.findIndex(item => item === orderItem)
      orderItem.files.find(f => f.name === file.name).storageId = storageId
      return Object.assign([], prevState, { [rowIndex]: orderItem })
    })
  }

  const confirmationDialog = (dialog) => {
    if (JSON.stringify(order.orderItems) === JSON.stringify(newOrderItems) && newComment === order.comment && !newOfferDeadline) {
      setEdit(false)
    } else {
      dialog(true)
    }
  }

  const cancelEdit = () => {
    setNewOrderItems(lodash.cloneDeep(order.orderItems))
    setEdit(false)
    showCancelEditDialog(false)
  }

  const approveEdit = async () => {
    if (isApproveDialogDisabled) return
    setIsApproveDialogDisabled(true)
    await orderApi.editOpenOrder(order.id, newOrderItems, newComment, newOfferDeadline)
      .finally(function () {
        setIsApproveDialogDisabled(false)
      })
    setEdit(false)
    showApproveEditDialog(false)
    getOrder()
  }

  const back = () => {
    if (props.offerId) {
      history.push(`/offers/${order.id}`)
    } else {
      props.setOrderId(null)
    }
  }

  return (
    <Box>

      <Box mb={2}>
        <Button color='secondary' variant='contained' onClick={back}>
          Tilbage
        </Button>
      </Box>

      <Box className={classes.container} p={2}>

        <Typography variant='h5'>
          Din ordre består af følgende
        </Typography>

        <Box className={classes.container} mt={2}>
          <FileTable
            editExisting={edit}
            orderItems={edit ? newOrderItems : order.orderItems}
            setOrderItems={setNewOrderItems}
            uploadFile={storeFile}
          />
        </Box>

        <Box display='flex' justifyContent='flex-end' width='100%' my={3} pr={3}>
          <Typography variant='h5'>
            Ordrestørrelse: {order.orderItems.reduce((a, b) => Number(a) + Number(b.quantity), 0)} stk.
          </Typography>
        </Box>

        <Box mt={2} display='flex' justifyContent='center'>

          {!edit && <Button color='red' variant='contained' onClick={() => setEdit(true)}>
            Rediger ordre
          </Button>}

          {edit && <React.Fragment>
            <Button color='red' variant='contained' onClick={() => confirmationDialog(showCancelEditDialog)}>
              Annuller
            </Button>

            <Box ml={2}>
              <Button variant='contained' onClick={() => confirmationDialog(showApproveEditDialog)}>
                Gem rettelser
              </Button>
            </Box>
          </React.Fragment>}

        </Box>
        <CancelEditDialog open={cancelEditDialog} cancelEdit={cancelEdit} close={() => showCancelEditDialog(false)} />
        <ApproveEditDialog open={approveEditDialog} approveEdit={approveEdit} close={() => showApproveEditDialog(false)} />
      </Box>

      {order.rfqType === 'direct' && <Box>
        <Companies suppliers={order.suppliers} orderId={order.id} />
      </Box>}

      {order.rfqType === 'open' && <Box>
        <ReqSummary requirements={order.orderFilters} />
      </Box>}

      <Box>
        <OrderDetails
          values={order.orderInfo}
          edit={edit}
          setNewOfferDeadline={setNewOfferDeadline}
          newOfferDeadline={newOfferDeadline}
        />
      </Box>

    </Box >
  )
}

const useStyles = makeStyles({
  container: {
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    backgroundColor: 'white'
  },
  commentField: {
    border: '1px solid #C7C7C7',
    borderRadius: '5px',
    minHeight: '100px'
  }
});