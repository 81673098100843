import axios from 'axios'
import { API_ENDPOINT } from '.'

const API_ROUTE = `${API_ENDPOINT}/data/method`

export async function getSupplierMethods() {
  const url = `${API_ROUTE}/supplierMethods`
  const response = await axios.get(url)
    .catch(err => err.response)

  return response ? response.data : null
}