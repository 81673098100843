import clsx from 'clsx'

import CancelIcon from '@mui/icons-material/Cancel'
import {
  Box, Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import CheckIcon from '../../assets/images/check.svg'
import FilterGroup from '../findSupplier/filterGroup'
import { componentStyles } from '../styles'

import React, { useEffect, useState } from 'react'
import MultipleMethodsDialog from '../dialogs/multipleMethodsDialog'

export default function ProductionRequirements(props) {
  const _classes = componentStyles()
  const classes = useStyles()
  const { filters, setFilters, supplierMethods, supplierMaterials, supplierCertifications } = props

  const [filterWarning, setFilterWarning] = useState(true)
  const [multipleMethodsDialog, showMultipleMethodsDialog] = useState(false)

  useEffect(() => {
    if (filterWarning && filters.methods.length === 2) {
      setFilterWarning(false)
      showMultipleMethodsDialog(true)
    }
  }, [filters.methods, filterWarning])

  const addServiceFilter = (name, id, event) => {
    if (event.target?.checked) {
      setFilters({ ...filters, [name]: [...filters[name], id] })
    } else {
      setFilters({ ...filters, [name]: filters[name].filter(f => f !== id) })
    }
  }

  const renderChosenFilters = (filters, all, type, title) => {
    return <>
      <Box mt={3} mb={1}>
        <Typography variant='h4'>
          {title}
        </Typography>
      </Box>

      {React.Children.toArray(
        filters.map(filter => <Box display='flex' alignItems='center' justifyContent={'space-between'} mt={1}>

          <Box display='flex' alignItems='center'>
            <img src={CheckIcon} alt='' style={{ marginRight: '8px', width: '18px', height: '18px' }} />
            <Box mr={1}>
              <Typography variant='h6'>
                {all.find(a => a.id === filter)?.name}
              </Typography>
            </Box>
          </Box>

          <Box display='flex' onClick={() => addServiceFilter(type, filter, {})}>
            <CancelIcon color='red' fontSize='small' />
          </Box>

        </Box>)
      )}

      {filters.length < 1 &&
        <Typography fontStyle={'italic'}>
          {`Ingen ${title.toLowerCase()} valgt`}
        </Typography>
      }

    </>
  }

  return (
    <Box className={classes.container}>

      <Box className={clsx(_classes.container, classes.reqContainer)}>

        <Box textAlign={'center'}>
          <Typography variant='h3'>
            Hvem skal vi underrette?
          </Typography>
        </Box>

        <Box my={3}>
          <Typography>
            De valgte filtre bruges til at underrette producenter med de rette kompetencer om din ordre.
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography variant='h4'>
            Trin 1 - Vælg produktionsmetode(r)
          </Typography>
        </Box>

        <FilterGroup
          search
          currentFilters={filters.methods}
          addFilter={(id, event) => addServiceFilter('methods', id, event)}
          name={"Produktionsmetode"}
          data={supplierMethods.map(m => { return { id: m.methodId, count: m.supplierCount, name: m.method.name, category: m.method.category } })}
        />

        <Box mb={2} mt={1}>
          <Typography variant='h4'>
            Trin 2 - Vælg materiale
          </Typography>
        </Box>

        <FilterGroup
          currentFilters={filters.materials}
          addFilter={(id, event) => addServiceFilter('materials', id, event)}
          name={"Materiale"}
          data={supplierMaterials.map(m => { return { id: m.materialId, count: m.supplierCount, name: m.material.name, category: m.material.type } })}
        />

        <Box mb={2} mt={1}>
          <Typography variant='h4'>
            Trin 3 - Valgfrie kriterier
          </Typography>
        </Box>

        <FilterGroup
          currentFilters={filters.certifications}
          addFilter={(id, event) => addServiceFilter('certifications', id, event)}
          name={"Certificering"}
          data={supplierCertifications.map(m => { return { id: m.certificationId, count: m.supplierCount, name: m.certification.name, category: m.certification.category } })}
        />

      </Box>

      <Box ml={3} className={clsx(_classes.container, classes.filterContainer)}>

        <Box textAlign={'center'}>
          <Typography variant='h3'>
            Dine valg
          </Typography>
        </Box>

        <Box className={classes.infoContainer} p={1} mt={3}>
          <Typography>
            Kun producenter med alle nedenstående søgefiltre bliver informeret om din henvendelse
          </Typography>
        </Box>

        {renderChosenFilters(filters.methods, supplierMethods.map(m => { return { id: m.methodId, name: m.method.name } }), 'methods', 'Produktionsmetoder')}

        {renderChosenFilters(filters.materials, supplierMaterials.map(m => { return { id: m.materialId, name: m.material.name } }), 'materials', 'Materialer')}

        {filters.certifications.length > 0 && renderChosenFilters(filters.certifications, supplierCertifications.map(m => { return { id: m.certificationId, name: m.certification.name.split(' (')[0] } }), 'certifications', 'Certificeringer')}

      </Box>

      {multipleMethodsDialog && <MultipleMethodsDialog open={multipleMethodsDialog} close={() => showMultipleMethodsDialog(false)} methods={supplierMethods.filter(s => filters.methods.includes(s.methodId))} />}

    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    margin: '8px auto 16px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  reqContainer: {
    width: '525px',
    background: 'white',
    padding: '24px'
  },
  filterContainer: {
    minWidth: '310px',
    padding: '24px',
    height: 'fit-content'
  },
  infoContainer: {
    width: '261px',
    textAlign: 'center',
    border: '1px solid #DD8500',
    borderRadius: '5px',
    margin: 'auto',
    marginTop: '24px',
  }
});