import { Box, Button, Typography, Checkbox, FormControlLabel, Stack, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FactoryIcon from '@mui/icons-material/Factory';

import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect, useState, useRef } from 'react'
import NumberFormat from 'react-number-format'
import { useHistory } from 'react-router-dom'
import * as orderApi from '../../api/order'
import AttachedFiles from '../../components/offers/attachedFiles'
import CustomerInfo from '../../components/offers/customerInfo'
import DeliveryDate from '../../components/offers/deliveryDate'
import ExtraCostTable from '../../components/offers/extraCostTable'
import OfferNumber from '../../components/offers/offerNumber'
import OrderItemsTable from '../../components/offers/orderItemsTable'
import Messages from '../../components/orders/messages'
import SupplierInfo from '../../components/orders/supplierInfo'
import { useQuery } from '../../helpers/hooks'

import { OrderStatus } from '../../helpers/enums'
import * as supplierAPI from '../../api/supplier'

export default function Order(props) {
  const classes = useStyles()
  const history = useHistory()
  const query = useQuery()

  const [offer, setOffer] = useState()
  const [awaitingSupplier, setAwaitingSupplier] = useState(true);
  const [loading, setLoading] = useState()
  const [accepted, setAccepted] = useState(false)
  const acceptRef = useRef()
  const backTo = query.get('backTo')

  useEffect(() => {
    getOrder()
    // eslint-disable-next-line
  }, [props.id])

  const acceptOffer = () => {
    acceptRef.current.reportValidity()
    if (!accepted) return
    props.acceptOffer(offer)
  }

  async function getOrder() {
    setLoading(true)
    const data = await orderApi.getOrder(props.id, props.offerId)
    if (!data) return
    setOffer(data)
    const requestData = await orderApi.getOpenOrder(props.id)
    if (!requestData) return
    setAwaitingSupplier(data.updatedAt < requestData.updatedAt)
    setLoading(false)
  }

  if (loading || !offer) {
    return <Box />
  }

  const onViewTradeTerms = () => {
    supplierAPI.routeToTradeTerms(offer.supplierId)
  }

  const renderOrderStatus = () => {
    if (offer.accepted) return "Status: Accepteret"
    if (offer.status === OrderStatus.BENCHMARKED) return "Status: Benchmarked"
    if (offer.status === OrderStatus.CLOSED) return "Status: Afvist"
    if (awaitingSupplier) return "Status: Afventer opdateret tilbud"
    if (offer.status === OrderStatus.OPEN) return "Status: Tilbud"
    return ""
  }

  const back = () => {
    if (backTo) {
      history.push('/orders?status=requests')
    } else if (offer.status === OrderStatus.COMPLETED) {
      history.push('/orders?status=completed')
    } else {
      history.push('/orders')
    }
  }

  return (
    <Box width={'100%'}>
      <Box display='flex'>
        <Stack gap={2} width={'66%'}>

          <Box className={classes.container}>
            <Messages requestId={offer.request.id} />
          </Box>

          <Box className={classes.container}>
            <OrderItemsTable orderId={offer.id} orderItems={offer.orderItems} />
          </Box>

          {offer.extraItems && offer.extraItems.length > 0 &&
            <Box className={classes.container} mt={2}>
              <ExtraCostTable extraItems={offer.extraItems} />
            </Box>}

          <Box display='flex' flexDirection={'column'} alignItems='flex-end' width='100%' >
            <Box className={classes.container} width='fit-content' p={2} mb={2}>
              <Typography variant='h5'>
                Ordrestørrelse:
                <NumberFormat
                  value={offer.orderItems.reduce((a, b) => Number(a) + Number(b.quantity), 0)}
                  displayType={'text'} thousandSeparator={'.'}
                  decimalSeparator={','}
                  suffix={' stk.'}
                  style={{ marginLeft: '5px' }}
                />
              </Typography>
            </Box>

            <Box className={classes.container} width='fit-content' p={2} ml={2} border='2px solid orange'>
              <Typography variant='h5'>
                {`Total pris ekskl. moms: `}
                <NumberFormat
                  value={Number(offer.total)}
                  displayType={'text'}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  decimalScale={2}
                  suffix={' kr.'}
                  style={{ marginLeft: '5px' }}
                />
              </Typography>
            </Box>
          </Box>
        </Stack>

        <Box width={'34%'} ml={2}>
          <Stack gap={2}>
            <Box>
              <Accordion defaultExpanded={false} elevation={0} sx={accordionStyle}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                  <ShoppingCartIcon />
                  <Typography pl={1} variant='h5'>Ordreinformation</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CustomerInfo nda={offer.nda} values={offer.customerInfo} orderId={props.id} />
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box>
              <Accordion defaultExpanded={true} elevation={0} sx={accordionStyle}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                  <FactoryIcon />
                  <Typography pl={1} variant='h5'>Leverandørens information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <SupplierInfo supplier={offer.supplier} />
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box className={classes.container} p={2}>
              <OfferNumber offerNumber={offer.offerNumber} />
            </Box>

            <Box className={classes.container} p={2}>
              <Typography variant='h5'>
                {renderOrderStatus()}
              </Typography>
              <Box display='flex' mt={2}>
                <AttachedFiles isNotAttached={!offer.fileId} offerId={offer.id} orderId={offer.orderId} name={offer.fileName} />
                <DeliveryDate deliveryDate={offer.deliveryDate} reference={offer.reference} />
              </Box>
              {offer.status === 'open' &&
                <Stack direction="row" component="form" ref={acceptRef} >
                  <FormControlLabel control={<Checkbox required name="accept" checked={accepted} onChange={(e) => setAccepted(e.target.checked)} />} label={offer.tradeTerms ? "Jeg har læst og accepterer" : "Jeg accepterer det vedhæftede tilbud"} />
                  {
                    offer.tradeTerms && <Typography onClick={onViewTradeTerms} sx={{ pt: 1, cursor: 'pointer', textDecoration: 'underline', color: '#7B61FF' }}> handelsbetingelserne
                    </Typography>
                  }
                </Stack>
              }
              {(offer.status !== 'open' && offer.tradeTerms) &&
                <Box mt={1}>
                  <Typography onClick={onViewTradeTerms} sx={{ cursor: 'pointer', textDecoration: 'underline', color: '#7B61FF' }} >
                    Handelsbetingelser
                  </Typography>
                </Box>
              }
            </Box>
          </Stack>
        </Box>
      </Box >

      <Box display='flex' justifyContent='space-between' mt={3} pb={2}>
        <Button variant='contained' color='secondary' onClick={props.back ? props.back : back}>
          Tilbage
        </Button>
        {!awaitingSupplier && props.acceptOffer &&
          <Box>
            {(offer.status === 'open' || offer.status === 'expired') &&
              <Button variant='contained' onClick={() => acceptOffer()}>
                Accepter tilbud
              </Button>
            }
          </Box>
        }
      </Box>
    </Box >
  )
}

const accordionStyle = {
  boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
  borderRadius: '5px'
}

const useStyles = makeStyles({
  container: {
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    borderRadius: '5px',
    backgroundColor: 'white'
  },
  commentField: {
    border: '1px solid #C7C7C7',
    borderRadius: '5px',
    minHeight: '100px'
  }
});